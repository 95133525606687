import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

// funcoes proprias.
import Api from '../Api';
import BarraInferior from '../componentes/BarraInferior';
import DiscoHeader from '../componentes/DiscoHeader';
import Carrossel from '../componentes/Carrossel';
import IfComponent from '../componentes/IfComponent';

function Disco(props) {
    //const {  } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ artistas, setArtistas ] = useState([]);
    const [ generos, setGeneros ] = useState(null);
    const [ paises, setPaises ] = useState(null);
    const [ disco, setDisco ] = useState({});
    const [ imagens, setImagens ] = useState([]);
    const [ desejo, setDesejo ] = useState('fa fa-fw fa-heart');
    const [ showModal, setShowModal ] = useState(false);

    const SelectItemsESTADO = [
        {value: "VM - MINT", label: "M - MINT" },
        {value: "NM - NEAR MINT", label: "NM - NEAR MINT" },
        {value: "EX - EXCELENT", label: "EX - EXCELENT" },
        {value: "VG+ - VERY GOOD+", label: "VG+ - VERY GOOD+" },
        {value: "VG - VERY GOOD", label: "VG - VERY GOOD" },
        {value: "G - GOOD", label: "G - GOOD" }
    ];

    const SelectItemsRARIDADE = [
        {value: 1, label: "1 - NÃO RARO" },
        {value: 2, label: "2 - POUCO RARO" },
        {value: 3, label: "3 - RARO" },
        {value: 4, label: "4 - MUITO RARO" },
        {value: 5, label: "5 - RARÍSSIMO" }
    ];

    const SelectItemsTAMANHO = [
        {value: 12, label: "12\"" },
        {value: 10, label: "10\"" },
        {value: 7, label: "7\"" }
    ];

    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ];
        
    function VerDisco() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('disco', id);

        Api.post('discos.php',formData)
        .then((response) => {
            setDisco(response.data.msg);
            if (response.data.erro==='N') {
                setDisco(response.data.msg);
                setImagens(response.data.msg.FOTOS);              
                setDesejo(response.data.msg.DES_DISCO===0?'fa fa-fw fa-heart':'fa fa-fw fa-heart text-danger');
            } else {
                setDisco({DIS_CODIGO:0});
                setImagens([]);                
                setDesejo('fa fa-fw fa-heart');
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }   

    async function comboBoxesOptions() {
        const formData=new FormData();
        formData.set('op','combo');
        
        const response1 = await Api.post('artistas.php',formData);
        setArtistas(response1.data.msg);

        const response2 = await Api.post('generos.php',formData);
        setGeneros(response2.data.msg);

        const response3 = await Api.post('paises.php',formData);
        setPaises(response3.data.msg);
    }
    
    useEffect(() => {
        VerDisco();
        comboBoxesOptions();
        //$('#DIS_ANO').mask('0000');
        //$('#DIS_VALOR').mask("##0.00", {reverse: true});
        // eslint-disable-next-line
    },[]);

    function Clonar() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'clonar');
        bodyFormData.set('disco', id);

        Api.post('discos.php',bodyFormData)
        .then((response) => {
            if (response.data.erro==='N') {
                setDisco(response.data.msg);
                setImagens(response.data.msg.FOTOS);
                navigate('/disco/'+response.data.msg.DIS_CODIGO);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        });
    }

    function btnClonar() {
        Swal.fire({
            title: 'Clonar', 
            text: 'Deseja clonar este disco?', 
            icon: 'question', 
            showCancelButton: true,
            cancelButtonText: 'Não quero', 
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sim, quero uma cópia', 
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                Clonar();
            } else {
                //console.log('nao');
            }
        });  
    }

    async function btnDesejo() {
        const formData = new FormData();
        formData.set('op','desejo');
        formData.set('disco',id);
        const response = await Api.post('discos.php',formData);
        if (response.data.erro==='S') {
            setDesejo('fa fa-fw fa-heart');
            Swal.fire({title: 'Aaahhh!', text: response.data.msg, icon: 'info', showConfirmButton: false, timer: 1500});            
        } else {
            setDesejo('fa fa-fw fa-heart text-danger');
            Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
        }
    }

    function btnChat() {
        Swal.fire({title: 'Sorry!', text: 'Ainda não disponível.', icon: 'info', showConfirmButton: false, timer: 1500});
    }

    function btnEditar() {
        //setRegistroSelecionado({ ART_CODIGO: '', ART_NOME: '' });
        setShowModal(true);
    }

    function Excluir() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'excluir');
        bodyFormData.set('disco', disco.DIS_CODIGO);

        Api.post('discos.php',bodyFormData)
        .then((response) => {
            if (response.data.erro==='N') {
                setDisco({});
                setImagens([]);
                navigate('/vitrine');
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        });
    }

    function btnExcluir() {
        Swal.fire({
            title: 'Excluir?', 
            text: 'Deseja realmente excluir este disco?', 
            icon: 'question', 
            showCancelButton: true,
            cancelButtonText: 'Nãooooo', 
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sim, taca pau', 
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                Excluir();
            } else {
                //console.log('nao');
            }
        });  
    }

    function Botoes1() {
        return <>
            <div className="col-4">
                <Link to={'/discofotos/'+id} className="btn btn-success w-100"><i className="fa fa-fw fa-camera"></i><br/>Fotos</Link>
            </div>
            <div className="col-4">
                <Link to={'/discofotosenviar/'+id} className='btn btn-success w-100'><i className="fa fa-fw fa-camera"></i><br/>Add Fotos</Link>
            </div> 
        </>
    }

    function Botoes2() {
        return <>
            <div className="col-4">
                <button className="btn btn-success w-100" onClick={btnDesejo}><i className={desejo}></i><br/>Desejo</button>
            </div>
            <div className="col-4">
                <button className="btn btn-success w-100" onClick={btnChat}><i className="fa fa-fw fa-comments"></i><br/>Chat</button>
            </div>  
        </>
    }

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        setDisco({...disco, [name]: valor});
    }

    const setCampoValor = (e) => {
        const { name, value } = e.target;
        setDisco({...disco, [name]: value});
    }  
    
    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('DIS_CODIGO',disco.DIS_CODIGO);
        formData.set('DIS_TITULO',disco.DIS_TITULO);
        formData.set('DIS_ARTISTA',disco.DIS_ARTISTA);
        formData.set('DIS_GENERO',disco.DIS_GENERO);
        formData.set('DIS_PAIS',disco.DIS_PAIS);
        formData.set('DIS_ESTADO',disco.DIS_ESTADO);
        formData.set('DIS_RARIDADE',disco.DIS_RARIDADE);
        formData.set('DIS_TAMANHO',disco.DIS_TAMANHO);
        formData.set('DIS_STATUS',disco.DIS_STATUS);
        formData.set('DIS_ANO',disco.DIS_ANO);
        formData.set('DIS_VALOR',disco.DIS_VALOR);
        formData.set('DIS_CODIGOBARRAS',disco.DIS_CODIGOBARRAS);
        formData.set('DIS_OBSERVACOES',disco.DIS_OBSERVACOES);
        const response = await Api.post('discos.php',formData);
        if (response.data.erro==='N') {
            setShowModal(false); 
            Swal.fire({position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
        } else {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'}); 
        }
    }

    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">DETALHES DO DISCO</p></div>
                        <div className="">
                            <div className="link-icone me-3">
                                {disco.DIS_CODIGO>0 && (disco.DIS_USUARIO===disco.USUARIOLOGADO)?<Link to='' onClick={btnEditar}><i className="fa fa-2x fa-fw fa-pencil"></i></Link>:<span>&nbsp;</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={disco.DIS_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o disco.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={disco.DIS_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 py-2">
                <IfComponent condicional={ imagens.length > 0 }>
                    <div className="container limit-width p-0 pb-2">
                        <Carrossel fotos={imagens} />
                    </div>
                </IfComponent>
                <DiscoHeader 
                    imagens={imagens}
                    ART_NOME={disco.ART_NOME}
                    DIF_ARQUIVO={disco.DIF_ARQUIVO}
                    DIS_ANO={disco.DIS_ANO}
                    DIS_CODIGO={disco.DIS_CODIGO}
                    DIS_RARIDADE={disco.DIS_RARIDADE}
                    DIS_TAMANHO={disco.DIS_TAMANHO}
                    DIS_TITULO={disco.DIS_TITULO}
                    DIS_VALOR={disco.DIS_VALOR}
                />
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Gênero</sup></p><p className="lh-1 m-0">{disco.GEN_DESCRICAO}</p></li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Estado</sup></p><p className="lh-1 m-0">{disco.DIS_ESTADO}</p></li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">País</sup></p><p className="lh-1 m-0">{disco.PAI_NOMEBR}</p></li>  
                    <li className="list-group-item p-2">    
                        <div className="d-flex justify-content-between align-items-center">      
                            <div><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Proprietário</sup></p><p className="lh-1 m-0"><Link to={'/profile/'+disco.DIS_USUARIO} className="link-preto stretched-link">{disco.USU_NOME}</Link></p></div>      
                            <div><i className="fa fa-fw fa-chevron-right"></i></div>    
                        </div>  
                    </li>  
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Código de Barras</sup></p><p className="lh-1 m-0">&nbsp;</p></li>  
                    <li className="list-group-item p-2">    
                        <div className="row">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Data/Hora Cadastro</sup></p><p className="lh-1 m-0">{disco.DIS_DTCADASTRO} {disco.DIS_HRCADASTRO}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Data/Hora Atualização</sup></p><p className="lh-1 m-0">{disco.DIS_DTATUALIZACAO} {disco.DIS_HRATUALIZACAO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">Detalhes</sup></p><span className="lh-1 m-0"><section dangerouslySetInnerHTML={{ __html: disco.DIS_OBSERVACOES || '-' }} /></span></li>
                </ul>
                <div className="row gx-2 mb-5">
                    {disco.DIS_USUARIO===disco.USUARIOLOGADO?<Botoes1 />:<Botoes2 />}
    
                                        
                    <div className="col-4">
                        <button className="btn btn-success w-100" onClick={btnClonar}><i className="fa fa-fw fa-record-vinyl"></i><br/>Clonar</button>
                    </div>
                    <div className="col-12 mt-5">
                        {   disco.DIS_USUARIO===disco.USUARIOLOGADO
                                ? <button className="btn btn-danger w-100" onClick={btnExcluir}><i className="fa fa-fw fa-trash"></i> Excluir Disco</button>
                                : ""
                        }
                    </div>
                </div>             
            </div>
        </IfComponent>
        <BarraInferior active=""/>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height" closeButton>
                <Modal.Title><h5 className="modal-title">EDIÇÃO DO DISCO</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row mb-0 mt-0 gx-2 p-inputtext-sm">
                    <div className="col-12 mb-2">
                        <input type="hidden" name="ART_CODIGO" id="ART_CODIGO" value='' />
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="120" name="DIS_TITULO" onChange={(e) => setCampo(e)} value={disco.DIS_TITULO} />
                            <label htmlFor="DIS_TITULO">TÍTULO DO DISCO</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_ARTISTA" value={disco.DIS_ARTISTA || ''} options={artistas} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_ARTISTA">ARTISTA/BANDA</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_GENERO" value={disco.DIS_GENERO || ''} options={generos} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_GENERO">GÊNERO</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_PAIS" value={disco.DIS_PAIS || ''} options={paises} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_PAIS">PAÍS</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_ESTADO" value={disco.DIS_ESTADO || ''} options={SelectItemsESTADO} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_ESTADO">ESTADO DO DISCO</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_RARIDADE" value={disco.DIS_RARIDADE || ''} options={SelectItemsRARIDADE} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_RARIDADE">NÍVEL DE RARIDADE</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_TAMANHO" value={disco.DIS_TAMANHO || ''} options={SelectItemsTAMANHO} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_TAMANHO">TAMANHO</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_STATUS" value={disco.DIS_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_STATUS">STATUS</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <InputMask className="w-100" name="DIS_ANO" mask="9999" value={disco.DIS_ANO || ''} onChange={(e) => setCampo(e)} ></InputMask>
                            <label htmlFor="DIS_ANO">ANO</label>
                        </span>                        
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <InputNumber className="w-100 p-inputwrapper-filled" name="DIS_VALOR" value={disco.DIS_VALOR || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR"/>
                            <label htmlFor="DIS_VALOR">VALOR</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="30" name="DIS_CODIGOBARRAS" onChange={(e) => setCampo(e)} value={disco.DIS_CODIGOBARRAS} />
                            <label htmlFor="DIS_CODIGOBARRAS">CÓDIGO DE BARRAS</label>
                        </span>
                    </div>
                    <div className="col-12 mb-4">
                        <label htmlFor="DIS_OBSERVACOES">Detalhes</label>
                        <CKEditor
                            name='DIS_OBSERVACOES'
                            editor={ ClassicEditor }
                            config={{ toolbar: [ 'bold', 'italic', '|', 'numberedList', 'bulletedList'] /*'underline', 'strikethrough', 'textColor'*/ }}
                            data={disco.DIS_OBSERVACOES}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                                //console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDisco({...disco, ['DIS_OBSERVACOES']: data}); //eslint-disable-line
                            } } 
                            onBlur={ (event, editor) => {
                                //console.log( 'Blur.', editor );
                            } }
                            onFocus={ (event, editor) => {
                                //console.log( 'Focus.', editor );
                            } }
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light py-0 limit-height'>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
                <Button variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
            </Modal.Footer>
        </Modal>        
    </>
}

export default Disco;