import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function BarraSuperior(props) {
    const navigate = useNavigate();
    
    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        {props.linkleft && props.linkleft!==""?
                            <div className="link-icone">
                                {   props.linkleft==='voltar' 
                                        ? <Link to='' onClick={() => navigate(-1)}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link>
                                        : <Link to={props.linkleft}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link>
                                }
                            </div>:""
                        }
                        <span className="position-absolute top-50 start-50 translate-middle text-light fw-bolder">{props.titulo}</span>
                        {props.linkright && props.linkright!==""?
                            <div className="link-icone"><Link to={props.linkright}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>:""
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default BarraSuperior;