import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom'; 

// funcoes proprias.
//import LogoDiscos from '../../componentes/LogoDiscos';
import { AuthContext } from '../../contextos/Auth';
import './Login.css';

function Login() {

    const { logado, usuarioStatus, mensagem, logar } = useContext(AuthContext);

    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [acessando, setAcessando] = useState(false);

    if (usuarioStatus==='I') {
        return <Navigate to='/confirmarcadastro' />
    }

    if (logado) {
        return <Navigate to='/vitrine' />
    }

    async function Acessar() {     
        setAcessando(true);
        await logar(login, senha);      
        setAcessando(false);
    }

    return <div className="container-fluid bg-dark">
        <div className="d-flex align-items-center text-center form-login-container">
            <form className="form-login w-100" name="FormularioLogin" id="FormularioLogin">
                {/* <LogoDiscos width='280px'/> */}
                <img src='/imagens/logo_discos_white.svg' style={{maxWidth: '280px'}} alt='Discos' />
            { mensagem!=='' ? <div className="alert alert-danger mb-2" role="alert">{mensagem}</div> : null }
            <div className="form-floating mt-3">
                <input onChange={(e) => setLogin(e.target.value)} type="email" className="form-control mb-2 border-0 bg-light bg-opacity-10 text-light disable-form-control-focus" id="login" name="login" placeholder="Seu email" autoFocus/>
                <label className="form-label text-light" htmlFor="user">Seu email</label>
            </div>
            <div className="form-floating mb-3">
                <input onChange={(e) => setSenha(e.target.value)} type="password" className="form-control border-0 bg-light bg-opacity-10 text-light disable-form-control-focus" id="senha" name="senha" placeholder="Sua senha" autoComplete='false'/>
                <label className="form-label text-light" htmlFor="pass">Sua senha</label>
            </div>
            {/* <div className="form-check form-switch text-start">
                <input className="form-check-input" type="checkbox" role="switch" id="lembrar" name="lembrar" />
                <label className="form-check-label" htmlFor="lembrar">Lembrar de mim</label>
            </div> */}
            <input type="hidden" name="op" value="login"/>
            {   acessando
                    ? <button className="btn btn-lg btn-success w-100 mt-4" type="button" disabled><i className='fa fa-fw fa-spin fa-spinner'></i> Verificando credenciais...</button>
                    : <button className="btn btn-lg btn-success w-100 mt-4" type="button" onClick={Acessar}>Acessar</button>
            }
            <Link to='/criarconta' className="btn btn-lg btn-outline-success w-100 mt-2 text-light">Criar Conta</Link>
            <div className="d-flex justify-content-between">
                <p className="mt-5"><Link to="/reset">Esqueci minha senha</Link></p>
                <p className="mt-5"><Link to="/termos">Termos de uso</Link></p>
            </div>
            </form>
        </div>
    </div>    
}

export default Login;