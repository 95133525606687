import React, { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
//import ScrollToBottom from 'react-scroll-to-bottom'; 
//import io from 'socket.io-client';

import Api from '../Api'; 
import { SocketContext } from '../contextos/Socket';
import { AuthContext } from '../contextos/Auth';

function BarraChatsView(props) {
    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone me-2"><Link to='/chats'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className=""><Link to={'/profile/'+props.id}><img src={props.avatar} className="img-fluid rounded-circle" alt='' style={{maxHeight: '46px'}}/></Link></div>
                        <div className="w-100 text-light text-truncate ms-2"><p className="m-0 text-truncate"><Link to={'/profile/'+props.id}>{props.nome}</Link></p><p className="small text-muted m-0">{props.status}</p></div>
                        <div className="link-icone text-light me-2"><i className="fa fa-2x fa-fw fa-ellipsis-v"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function BarraEnviar(props) {
    return <>
        <div className="bg-dark fixed-bottom">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100"><input className="form-control border-0 bg-light bg-opacity-10 text-light me-2 disable-form-control-focus" type="text" name="mensagem" value={props.value} onChange={props.onChange} onKeyPress={props.onKeyPress} placeholder="Fale alguma coisa..." /></div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" name="BtnEnviar" onClick={props.onButtonClick}>ENVIAR</button></div>
                    </div>
                </div>
            </div>
        </div>    
    </>
}

function MensagemCard(props) {
    const css1 = props.eu===props.from?'justify-content-end':'justify-content-start';
    const css2 = props.eu===props.from?'bg-success bg-opacity-50 text-end':'bg-secondary bg-opacity-25';
    const css3 = props.eu===props.from?'text-end':'';
    const css4 = props.lida==='S'?'text-primary':'text-secondary';
    return <>
        <div className={'mb-2 d-flex '+css1}>
            <div className={'rounded p-1 px-2 text-black text-wrap text-break '+css2} style={{maxWidth: '350px'}}>
            {props.mensagem}<br/><span className={'small text-black-50 '+css3}>{props.data} {props.hora} <i className={'fa fa-fw fa-check '+css4}></i></span>
            </div>
        </div>
    </>
}

function ChatsView(props) {
    const { id } = useParams();
    const finalMensagens = useRef(null);
    const [ listagem, setListagem ] = useState([]);
    const [ mensagem, setMensagem ] = useState('');
    const [ chat, setChat ] = useState([]);
    const [ eu, setEu ] = useState('');
    const [ ele, setEle ] = useState('');
    const [ status, setStatus ] = useState('Online');

    //const socket = io.connect('http://localhost:3001');
    const { socket } = useContext(SocketContext);
    const { usuarioNome } = useContext(AuthContext);

    const scrollToBottom = () => {
        finalMensagens.current.scrollIntoView({ behavior: "smooth" })
    }

    async function getListagem() {
        const formData = new FormData();
        formData.set('op','listarmsgs');
        formData.set('id',id);

        const response = await Api.post('chats.php',formData);
        setChat(response.data.msg.CHAT);
        setListagem(response.data.msg.MSGS);
        setEu(response.data.msg.CHAT.CHA_USER1);
        setEle(response.data.msg.CHAT.CHA_USER2);
    }

    async function marcarLidas() {
        const formData = new FormData();
        formData.set('op','lidas');
        formData.set('id',id);
        const response = await Api.post('chats.php',formData);
        console.log(response.data)
        if (response.data.erro==='S') {
            console.log(response.data.msg);
        }
    }    

    useEffect(scrollToBottom, [listagem]);

    useEffect(() => {
        socket.emit('sala',id);
        marcarLidas();
        getListagem();
        //eslint-disable-next-line
    },[])

    useEffect(() => {
        console.log('effect socket')
        socket.on('receberMensagem', (data) => {
            console.log('effect-data ',data);
            if (data.erro==='N') {
                setListagem((lista) => [...lista, data.msg]);
            } else {
                console.log('ERRO: ',data.msg);
            }
            // const msg= {
            //     CHM_CODIGO: 60,
            //     CHM_DATA: '17/02/2022',
            //     CHM_HORA: '16:53',
            //     CHM_USUARIO: 1,
            //     CHM_MENSAGEM: mensagem
            // }            
        });

        socket.on('typing', (data) => {
            console.log('digitando');
            setStatus('Digitando...');
            const dat=new Date(Date.now()).getDate()+' '+new Date(Date.now()).getTime();
            setTimeout(() => {
                setStatus(dat);
            },3000);
        })
    },[socket]);
    
    async function enviarMensagem() {       
        if (mensagem !== "") {
            const messageData = {
                token: localStorage.getItem('token'),
                chatid: id,
                destino: ele,
                mensagem
            }
            await socket.emit('enviarMensagem', messageData); //o retorno do envio da mensagem será recebido pelo effect (receberMensagem)
            // const msg = {
            //     CHM_CODIGO: Math.random(),
            //     CHM_DATA: new Date(Date.now()).getDay()+'/'+new Date(Date.now()).getMonth(),
            //     CHM_HORA: new Date(Date.now()).getHours()+'/'+new Date(Date.now()).getMinutes(),
            //     CHM_USUARIO: eu,
            //     CHM_MENSAGEM: mensagem
            // }
            // setListagem((lista) => [...lista, msg]);
            setMensagem('');
        }
    }
    
    const handleInputChange = (e) => {
        const { value } = e.target;
        setMensagem(value || '');
    }

    const handleKeyPress = (e) => {
        const data = { chatid: id, nome: usuarioNome }
        socket.emit('typing', data); //informa o server que está digitando.
    }

    return <>
        <BarraChatsView nome={chat.USU_NOME} avatar={chat.USU_AVATAR} id={chat.CHA_USER2} status={status}/>
        <div className="container-fluid limit-width my-5 py-2">
            <div className="message-container" id="conversa">
                {
                    listagem.map((msg) => {
                        return <MensagemCard key={msg.CHM_CODIGO} mensagem={msg.CHM_MENSAGEM} data={msg.CHM_DATA} hora={msg.CHM_HORA} eu={eu} from={msg.CHM_USUARIO}/>
                    })
                }
            </div>
        </div>
        <div ref={finalMensagens} />
        <BarraEnviar value={mensagem} onKeyPress={handleKeyPress} onChange={handleInputChange} onButtonClick={enviarMensagem}/>
    </>
}

export default ChatsView;