import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import BarraInferior from '../componentes/BarraInferior';
import AcervoCard from '../componentes/AcervoCard';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Acervo() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    
    //para o form de cadastro.
    const [ artistas, setArtistas ] = useState([]);
    const [ generos, setGeneros ] = useState(null);
    const [ paises, setPaises ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ disco, setDisco ] = useState({
        DIS_ANO: '',
        DIS_ARTISTA: '',
        DIS_CODIGOBARRAS: '',
        DIS_ESTADO: 'M - MINT',
        DIS_GENERO: '',
        DIS_OBSERVACOES: '',
        DIS_PAIS: '',
        DIS_RARIDADE: 3,
        DIS_STATUS: 'A',
        DIS_TAMANHO: 12,
        DIS_TITULO: '',
        DIS_VALOR: ''
    });
    //const [ registroSelecionado, setRegistroSelecionado ] = useState(null);

    const SelectItemsESTADO = [
        {value: "VM - MINT", label: "M - MINT" },
        {value: "NM - NEAR MINT", label: "NM - NEAR MINT" },
        {value: "EX - EXCELENT", label: "EX - EXCELENT" },
        {value: "VG+ - VERY GOOD+", label: "VG+ - VERY GOOD+" },
        {value: "VG - VERY GOOD", label: "VG - VERY GOOD" },
        {value: "G - GOOD", label: "G - GOOD" }
    ];

    const SelectItemsRARIDADE = [
        {value: "1", label: "1 - NÃO RARO" },
        {value: "2", label: "2 - POUCO RARO" },
        {value: "3", label: "3 - RARO" },
        {value: "4", label: "4 - MUITO RARO" },
        {value: "5", label: "5 - RARÍSSIMO" }
    ];

    const SelectItemsTAMANHO = [
        {value: "12", label: "12\"" },
        {value: "10", label: "10\"" },
        {value: "7", label: "7\"" }
    ];

    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ];
    
    function listarAcervo(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);
                bodyFormData.set('desejo', 0);
                bodyFormData.set('acervo', true);
                       
            Api.post('vitrine.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarAcervo(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarAcervo(0,false,true);
    };
    
    const getArtistas = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('artistas.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setArtistas(response.data.msg);
            }
        });
    },[]);

    const getGeneros = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('generos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setGeneros(response.data.msg);
            }
        });
    },[]);    

    const getPaises = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('paises.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setPaises(response.data.msg);
            }
        });
    },[]);    

    useEffect(() => {
        getArtistas();
        getGeneros();
        getPaises();
    },[getArtistas,getGeneros,getPaises])

    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','inserir');
        formData.set('DIS_ANO',disco.DIS_ANO);
        formData.set('DIS_ARTISTA',disco.DIS_ARTISTA);
        formData.set('DIS_CODIGOBARRAS',disco.DIS_CODIGOBARRAS);
        formData.set('DIS_ESTADO',disco.DIS_ESTADO);
        formData.set('DIS_GENERO',disco.DIS_GENERO);
        formData.set('DIS_OBSERVACOES',disco.DIS_OBSERVACOES);
        formData.set('DIS_PAIS',disco.DIS_PAIS);
        formData.set('DIS_TAMANHO',disco.DIS_TAMANHO);
        formData.set('DIS_TITULO',disco.DIS_TITULO);
        formData.set('DIS_VALOR',disco.DIS_VALOR);
        const response = await Api.post('discos.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: 'Disco cadastrado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setShowModal(false);
            navigate('/disco/'+response.data.msg.DIS_CODIGO);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }     

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        setDisco({...disco, [name]: valor});
    }

    const setCampoValor = (e) => {
        const { name, value } = e.target;
        setDisco({...disco, [name]: value});
    }  

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone">&nbsp;</div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">MEU ACERVO</p></div>
                        <div className="link-icone"><Link to='' onClick={() => setShowModal(true)} ><i className="fa fa-2x fa-fw fa-edit"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                        {/* <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { setFinal(false); setPagina(0); setListagem([]); findGeneros() }} >Filtrar</button><input type="hidden" name="op" value="localizar"/></div> */}
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
        <div className="container limit-width pb-2"></div>
        <div className="container limit-width mt-5 py-5">
            {                     
                listagem.map(acervo => (
                    // <AcervoCard onClick={console.log('clicou acervoaaa')}
                    <AcervoCard 
                        key={acervo.DIS_CODIGO}
                        acervo={true}
                        ART_NOME={acervo.ART_NOME}
                        DES_DISCO={acervo.DES_DISCO}
                        DIF_ARQUIVO={acervo.DIF_ARQUIVO}
                        DIS_ANO={acervo.DIS_ANO}
                        DIS_CODIGO={acervo.DIS_CODIGO}
                        DIS_TITULO={acervo.DIS_TITULO}
                        DIS_RARIDADE={acervo.DIS_RARIDADE}
                        DIS_VALOR={acervo.DIS_VALOR}
                        GEN_DESCRICAO={acervo.GEN_DESCRICAO}
                        PAI_NOMEBR={acervo.PAI_NOMEBR}
                        USU_NOME={acervo.USU_NOME}
                    />                   
                ))
            }   
        </div>
        </IfComponent>           
        <div className="container limit-width bg-danger" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Nenhum vinil encontrado!</p>
            </div>                    
        </IfComponent>
        <BarraInferior active="acervo"/>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height" closeButton>
                <Modal.Title><h5 className="modal-title">CADASTRAR NOVO DISCO</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row mb-0 mt-0 gx-2 p-inputtext-sm">
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="120" name="DIS_TITULO" onChange={(e) => setCampo(e)} value={disco.DIS_TITULO} />
                            <label htmlFor="DIS_TITULO">TÍTULO DO DISCO</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_ARTISTA" value={disco.DIS_ARTISTA || ''} options={artistas} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_ARTISTA">ARTISTA/BANDA</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_GENERO" value={disco.DIS_GENERO || ''} options={generos} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_GENERO">GÊNERO</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_PAIS" value={disco.DIS_PAIS || ''} options={paises} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_PAIS">PAÍS</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_ESTADO" value={disco.DIS_ESTADO || ''} options={SelectItemsESTADO} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_ESTADO">ESTADO DO DISCO</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_RARIDADE" value={disco.DIS_RARIDADE || ''} options={SelectItemsRARIDADE} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_RARIDADE">NÍVEL DE RARIDADE</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_TAMANHO" value={disco.DIS_TAMANHO || ''} options={SelectItemsTAMANHO} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_TAMANHO">TAMANHO</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <Dropdown className="w-100" name="DIS_STATUS" value={disco.DIS_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label htmlFor="DIS_STATUS">STATUS</label>
                        </span>
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <InputMask className="w-100" name="DIS_ANO" mask="9999" value={disco.DIS_ANO || ''} onChange={(e) => setCampo(e)} ></InputMask>
                            <label htmlFor="DIS_ANO">ANO</label>
                        </span>                        
                    </div>
                    <div className="col-6 mb-2">
                        <span className="p-float-label">
                            <InputNumber className="w-100 p-inputwrapper-filled" name="DIS_VALOR" value={disco.DIS_VALOR || ''} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR"/>
                            <label htmlFor="DIS_VALOR">VALOR</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="30" name="DIS_CODIGOBARRAS" onChange={(e) => setCampo(e)} value={disco.DIS_CODIGOBARRAS} />
                            <label htmlFor="DIS_CODIGOBARRAS">CÓDIGO DE BARRAS</label>
                        </span>
                    </div>
                    <div className="col-12 mb-4">
                        <label htmlFor="DIS_OBSERVACOES">Detalhes</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            config={{ toolbar: [ 'bold', 'italic', '|', 'numberedList', 'bulletedList'] /*'underline', 'strikethrough', 'textColor'*/ }}
                            data={disco.DIS_OBSERVACOES}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                                //console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDisco({...disco, ['DIS_OBSERVACOES']: data}); //eslint-disable-line
                            } } 
                            onBlur={ (event, editor) => {
                                //console.log( 'Blur.', editor );
                            } }
                            onFocus={ (event, editor) => {
                                //console.log( 'Focus.', editor );
                            } }
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light py-0 limit-height'>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
                <Button variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
            </Modal.Footer>
        </Modal>        

    </>
}

export default Acervo;