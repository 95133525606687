import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

//funcoes proprias.
import { AuthContext } from '../contextos/Auth';

function ConfirmarCadastro() {
    const { usuarioNome, usuarioEmail, logout, confirmar, reenviar } = useContext(AuthContext);
    const [ codigo, setCodigo ] = useState('');
    const [ confirmando, setConfirmando ] = useState(false);
    const [ reenviando, setReenviando ] = useState(false);

    async function reenviarCodigo() {
        setReenviando(true);
        await reenviar(usuarioEmail);
        setReenviando(false);
    }

    async function confirmarCadastro(e) {
        e.preventDefault();
        setConfirmando(true);
        await confirmar(usuarioEmail,codigo);
        setConfirmando(false);
    }

    return <>
        <div className='fixed-top bg-dark'>
            <div className='container-fluid limit-width'>
                <div className='row'>
                    <div className='col-12 limit-height d-flex justify-content-between align-items-center'>
                        <div className=""><a href="/index.php\"><img src="/imagens/logo_discos_white_simples.svg" style={{maxHeight: '40px'}} alt="discos" /></a></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid limit-width py-5">
            <div className="row mt-5 gx-2 fs-6">
                <form name="FormularioCriarConta" method="post" onSubmit={(e) => confirmarCadastro(e)}>
                    <div className="col-12">
                        <p><b>{usuarioNome}</b>, enviamos um email para você com um código de 6 dígitos para verificação.<br/>O código foi enviado para <b className="text-danger">{usuarioEmail}</b><br/>Informe o código enviado no campo abaixo.</p>
                    </div>
                    <div className="col-12 form-floating mb-3"><input onChange={(e) => setCodigo((e).target.value)} type="text" className="form-control" name="confirmacao" required autoFocus/><label className="form-label" htmlFor="confirmacao">Código de Confirmação</label></div>
                    <div className="col-12 mb-5">
                        <p>Caso ainda não tenha recebido o código, por favor, verifique se não está na caixa de spam no seu aplicativo de emails.</p>
                        { reenviando
                            ? <p>Reenviando código de confirmação... <i className='fa fa-fw fa-spin fa-sync-alt'></i></p>
                            : <p>Você também pode <Link to="" onClick={reenviarCodigo} className="text-danger" >Reenviar código!</Link></p>
                        }
                    </div>
                    <div className="col-12">
                        <input type="hidden" name="op" value="confirmarcodigo" />
                        { confirmando
                            ? <button className="btn btn-lg btn-success w-100" type='submit' disabled><i className='fa fa-fw fa-spin fa-spinner'></i> Confirmando...</button>
                            : <button className="btn btn-lg btn-success w-100" type='submit'>CONFIRMAR</button>
                        }
                        <button className="btn btn-lg w-100 btn-primary mt-5" onClick={logout}>DESCONECTAR</button>          
                    </div>
                </form>
                <form name="FormularioReenviarCodigo" id="FormularioReenviarCodigo">
                    <input type="hidden" name="codigo" value='codigo' />
                    <input type="hidden" name="op" value="enviarcodigo" />
                </form>

            </div>
        </div>
    </>
}

export default ConfirmarCadastro;
