import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

//funcoes proprias.
import Api from '../Api';

function CriarConta() {
    const [ nome, setNome ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ pass, setPass ] = useState('');
    const [ pass2, setPass2 ] = useState('');

    function criarConta(e) {
        e.preventDefault();
        console.log(e.target);
        console.log(nome, email, pass, pass2);
        const formData = new FormData();
        formData.set('op','inserir');
        formData.set('nome',nome);
        formData.set('email',email);
        formData.set('pass',pass);
        formData.set('pass2',pass2);
        Api.post('criarconta.php',formData).then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
            } else {
                Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });        
    }

    return <>
    <div className="bg-dark fixed-top">
        <div className="container-fluid limit-width">
            <div className="row">
                <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                    <div className="link-icone"><Link to="/login"><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                    <div className=""><Link to='/login' href="/index.php"><img src="/imagens/logo_safesex_white.svg" style={{maxHeight: '40px'}} alt=""/></Link></div>
                    <span className="position-absolute top-50 start-50 translate-middle text-light fw-bold">CRIAR NOVO PERFIL</span>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid limit-width py-5">
        <div className="row mt-3 gx-2 fs-6">
            <form className="" onSubmit={(e) => criarConta(e)} name="FormularioCriarConta">
                <div className="col-12 form-floating"><input onChange={(e) => setNome(e.target.value)} type="text" className="form-control mb-2" name="nome" id="nome" placeholder="Nome" required/><label className="form-label" htmlFor="nome">Nome Completo</label></div>
                <div className="col-12 form-floating"><input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control mb-2" name="email" id="email" placeholder="Email" required/><label className="form-label" htmlFor="email">Seu email (não será divulgado)</label></div>
                <div className="col-12 form-floating"><input onChange={(e) => setPass(e.target.value)} type="password" className="form-control mb-2" name="pass" id="pass" placeholder="Senha" required/><label className="form-label" htmlFor="pass">Sua senha</label></div>
                <div className="col-12 form-floating"><input onChange={(e) => setPass2(e.target.value)} type="password" className="form-control" name="pass2" id="pass2" placeholder="Confirme a Senha" required/><label className="form-label" htmlFor="pass2">Confirme a senha</label></div>
                <div className="col-12">
                    <p className="mt-3 p-2">Clicando em <strong>CRIAR PERFIL</strong> você está concordando com os <Link to='/termos' className="text-success">Termos de Serviço.</Link></p>
                </div> 
  
                <div className="col-12 fixed-bottom bg-dark p-1">
                    <div className="container-fluid limit-width">
                        <div>
                            <input type="hidden" name="op" value="insert" />
                            <button className="btn btn-success w-100" type="submit">CRIAR PERFIL <i className="fa fa-fw fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
  
            </form>
        </div>
    </div>
    </>
}

export default CriarConta;