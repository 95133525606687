import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { InputText } from 'primereact/inputtext';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import BarraInferior from '../componentes/BarraInferior';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Artistas() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const navigate=useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ registroSelecionado, setRegistroSelecionado ] = useState(null);
    const [ ART_CODIGO, setART_CODIGO ] = useState('');
    const [ ART_NOME, setART_NOME ] = useState('');
    
    function listarArtistas(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
            
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);

            Api.post('artistas.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros.
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    logout();
                    //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });

        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarArtistas(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    useEffect(() => {
        if (registroSelecionado) {
            setART_CODIGO(registroSelecionado.ART_CODIGO);
            setART_NOME(registroSelecionado.ART_NOME);
            setShowModal(true);
        } else {
            setART_CODIGO('');
            setART_NOME('');
            setShowModal(false);
        }
    },[registroSelecionado]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarArtistas(0,false,true);
    }

    function AtualizarRegistro() {
        setRegistroSelecionado('');
        const op=ART_CODIGO>0?'atualizar':'inserir';
        var bodyFormData = new FormData();
        bodyFormData.set('op', op);
        bodyFormData.set('ART_CODIGO', ART_CODIGO);
        bodyFormData.set('ART_NOME', ART_NOME);

        Api.post('artistas.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                setPagina(0);
                setFinal(false);
                listarArtistas(0,false,true);
            } else {
                Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });
    }   

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
      
    const trailingActions = ({artista}) => (
        <TrailingActions>
            <SwipeAction destructive={false} onClick={() => {
                Swal.fire({
                    position: 'center',
                    icon: 'question',
                    title: 'Confirma Exclusão?',
                    text: 'Tem certeza que deseja excluir '+artista.ART_NOME+'?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, quero!',
                    cancelButtonText: 'Nãããoooooo',
                    confirmButtonColor: '#212529'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var bodyFormData = new FormData();
                        bodyFormData.set('op', 'excluir');
                        bodyFormData.set('ART_CODIGO', artista.ART_CODIGO);
                        Api.post('artistas.php',bodyFormData).then((response) => {
                            if (response.data.erro==='N') {
                                Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                                setPagina(0);
                                setFinal(false);
                                listarArtistas(0,false,true);
                            } else {
                                Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
                            }
                        }).catch(({response}) => {
                            console.log('catch: '+JSON.stringify(response));
                        });
                    }
                });
            }}>
                <div className="d-flex align-items-center bg-danger text-light text-center p-3" style={{cursor: 'pointer'}}><i className="fa fa-fw fa-trash"></i> Excluir</div>
            </SwipeAction>
        </TrailingActions>
    );

    function btnNovo() {
        setRegistroSelecionado({ ART_CODIGO: '', ART_NOME: '' });
        setShowModal(true);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='' onClick={() => navigate(-1)}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">ARTISTAS/BANDAS</p></div>
                        <div className="link-icone"><Link to='' onClick={btnNovo}><i className="fa fa-2x fa-fw fa-edit"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                        {/* <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { setFinal(false); setPagina(0); setListagem([]); findGeneros() }} >Filtrar</button><input type="hidden" name="op" value="localizar"/></div> */}
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
        <div className="container limit-width mt-5 py-5">
            <SwipeableList fullSwipe={false} type={ListType.IOS}>
            {                     
                listagem.map(artista => (
                    <SwipeableListItem key={artista.ART_CODIGO} trailingActions={trailingActions({ artista })}>
                    <div className="card border-0 border-bottom bg-transparent w-100" key={artista.ART_CODIGO}>
                        <div className="row">
                            <div className="col-12 ms-3 p-2">
                                <div><Link to="" onClick={() => setRegistroSelecionado(artista)} className="link-preto stretched-link swipe-element">{artista.ART_NOME}</Link></div>
                            </div>
                        </div>
                    </div>
                    </SwipeableListItem>
                ))
            }   
            </SwipeableList>
        </div>
        </IfComponent>           
        <div className="container limit-width bg-danger" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-sync-alt"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-microphone"></i></span><br/>Nenhum artista encontrado!</p>
            </div>                    
        </IfComponent>
        <BarraInferior active="artistas"/>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height" closeButton>
                <Modal.Title><h5 className="modal-title">Cadastro de Artista/Banda</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row mb-0 mt-0 gx-2">
                    <div className="col-12 mb-2">
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="120" id="ART_NOME" name="ART_NOME" value={ART_NOME} onChange={(e) => setART_NOME(e.target.value.toUpperCase())} />
                            <label htmlFor="ART_NOME">NOME ARTISTA/BANDA</label>
                        </span>                    
                        <input type="hidden" name="ART_CODIGO" id="ART_CODIGO" value={registroSelecionado && registroSelecionado.ART_CODIGO} />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light py-0 limit-height'>
                <Button variant="secondary" onClick={() => setRegistroSelecionado('')}>Cancelar</Button>
                <Button variant="success" onClick={() => {AtualizarRegistro();}}><i className="fa fa-fw fa-check"></i> Salvar</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default Artistas;