import React from 'react';

import Formata from './Formata';

function ProfileHeader(props) {
    return <>
        <div className="row">
            <div className="col-12 mt-2 bg-success bg-opacity-10">
                <div className="d-flex align-items-stretch">
                    <div>
                        <img data-fancybox="avatar" src={props.avatar} style={{maxWidth: '110px'}} alt={props.id} className="rounded-5 img-fluid me-2 p-0 border border-light border-3 shadow-sm" />
                    </div>
                    <div>
                        <p className="fw-lighter fs-3 m-0 p-0">{props.nome}</p>
                        <p className="fw-bold fs-6 m-0 mb-1 badge bg-success bg-opacity-50 rounded-pill">ID-<Formata formato="000000">{props.id}</Formata></p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ProfileHeader;