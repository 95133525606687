import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import Swal from 'sweetalert2';

import Upload from '../componentes/Upload/Upload';
import FileList from '../componentes/Upload/FileList';
import BarraSuperior from '../componentes/BarraSuperior';
import BarraInferior from '../componentes/BarraInferior';
import Api from "../Api";
import DiscoHeader from '../componentes/DiscoHeader';
import IfComponent from '../componentes/IfComponent';

//https://github.com/rocketseat-content/youtube-upload-nodejs-reactjs-frontend
function DiscosFotosEnviar() {
    const [ uploadedFiles, setUploadedFiles ] = useState(() => []);
    const { id } = useParams();
    const [ disco, setDisco ] = useState({});
    const [ imagens, setImagens ] = useState([]);

    useEffect(() => {       
        // const response = await Api.get('discosfotos_upload.php');
        // this.setState({
        //     uploadedFiles: response.data.map(file => ({
        //         id: file._id,
        //         name: file.name,
        //         readableSize: filesize(file.size),
        //         preview: file.url,
        //         uploaded: true,
        //         url: file.url
        //     }))
        // });
    },[]);
    
    useEffect(() => {
        //console.log('ef',uploadedFiles)
        //uploadedFiles.forEach(processUpload)
    },[uploadedFiles]);

    const updatedFile = useCallback((id,data) => {
        setUploadedFiles(prev=>{
            let unchangedFiles = prev.filter(file => file.id !== id);
            let updatedFile = {...prev.find(file => file.id === id),...data};
            let novo = [...unchangedFiles, updatedFile];
            //novo.sort((a,b) => { return a.name.localeCompare(b.name) }) //ordenacao por nome
            novo.sort((a,b) => { return (b.id - a.id) }) //ordenacao por id decrescente.
            return novo;
        });
    },[])

    const processUpload = useCallback(arquivo => {
        const formData = new FormData();
        formData.append("arquivo", arquivo.file, arquivo.name);
        formData.set('op','enviarfoto');
        formData.set('disco',id);
        Api.post('discosfotos_upload.php', formData, {
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                updatedFile(arquivo.id,{progress});
            }
        }).then(response => {
            console.log(response.data);
            if (response.data.erro==='N') {
                updatedFile(arquivo.id,{ uploaded: true, url: response.data.url });
            }
            if (response.data.erro==='S') {
                updatedFile(arquivo.id,{ error: true, errorMsg: response.data.msg });
            }
        }).catch((response) => {
            updatedFile(arquivo.id,{ error: true, errorMsg: response.data.msg });
        });
    },[id,updatedFile])

    const handleUpload = useCallback((files)=>{
        //files contem os arquivos dropados que devem ser adicionados na lista que tem o state.
        const newUploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            errorMsg: '',
            url: null
        }));
        setUploadedFiles( prev => [...prev, ...newUploadedFiles]);
        newUploadedFiles.forEach(processUpload); //processa somente os novos arquivos dropados.
    },[processUpload])

    const handleDelete = async (id) => { //eslint-disable-line
        console.log('eliminar foto');
        // await Api.delete(`posts/${id}`);
        // this.setState({
        //     uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
        // });
    };

    function VerDisco() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'verfotos');
        bodyFormData.set('disco', id);

        Api.post('discos.php',bodyFormData)
        .then((response) => {
            setDisco(response.data.msg);
            if (response.data.erro==='N') {
                setDisco(response.data.msg);
                setImagens(response.data.msg.FOTOS);
            } else {
                setDisco({DIS_CODIGO:0});
                setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }  

    useEffect(() => {
        VerDisco();
        // eslint-disable-next-line
    },[]);
        
    return <>
        <BarraSuperior titulo='ENVIO DE FOTOS' linkleft='voltar' linkright=''/>
        <IfComponent condicional={disco.DIS_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o disco.</p>
            </div> 
        </IfComponent>
        <IfComponent condicional={disco.DIS_CODIGO>0}>
            <div className='container limit-width mt-5 py-2'>
                <DiscoHeader
                    imagens={imagens}
                    ART_NOME={disco.ART_NOME}
                    DIF_ARQUIVO={disco.DIF_ARQUIVO}
                    DIS_ANO={disco.DIS_ANO}
                    DIS_CODIGO={disco.DIS_CODIGO}
                    DIS_RARIDADE={disco.DIS_RARIDADE}
                    DIS_TAMANHO={disco.DIS_TAMANHO}
                    DIS_TITULO={disco.DIS_TITULO}
                    DIS_VALOR={disco.DIS_VALOR}
                />
                <div className='mb-3'>
                    <Upload accept='image/*' onUpload={handleUpload}/>
                </div>
                {!!uploadedFiles.length && (
                    <FileList files={uploadedFiles} />
                    // <FileList files={uploadedFiles} onDelete={handleDelete} />
                )}
            </div>
        </IfComponent>
        <BarraInferior active='' />
    </>
}

export default DiscosFotosEnviar;