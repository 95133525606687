import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

// funcoes proprias.
import Api from '../Api';

function Contato() {
    const navigate = useNavigate();
    const [ mensagem, setMensagem ] = useState('');
    const [ nome, setNome ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ assunto, setAssunto ] = useState('');
    const [ destinatario, setDestinatario ] = useState('');

    function EnviarMensagem() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'enviar');
        bodyFormData.set('nome', nome);
        bodyFormData.set('email', email);
        bodyFormData.set('assunto', assunto);
        bodyFormData.set('destinatario', destinatario);
        bodyFormData.set('mensagem', mensagem);

        Api.post('contato.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                console.log(JSON.stringify(response));
                setNome('');
                setEmail('');
                setAssunto('');
                setDestinatario('');
                setMensagem('');
                Swal.fire({
                    title: 'Ebaaaaaa!',
                    text: response.data.msg,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/profile');
                    }
                });
            } else {
                Swal.fire({
                    title: 'Ah nãooooo!',
                    text: response.data.msg,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#212529'
                });
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });  
    }

    const SelectItemsDESTINATARIOS = [
        {value: "1", label: "Equipe de Suporte" }
    ];
    
    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to="/profile"><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <span className="position-absolute top-50 start-50 translate-middle text-light fw-bolder">CONTATO SUPORTE</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid limit-width py-5">
            <div className="row mt-3 mb-3 gx-2 p-inputtext-sm">
                <div className="col-lg-12 col-md-12 mb-2">
                    <span className="p-float-label">
                        <InputText className="w-100" id="nome" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} required/>
                        <label htmlFor="nome">NOME</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 mb-2">
                    <span className="p-float-label">
                        <InputText className="w-100" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <label htmlFor="email">EMAIL</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 mb-2">
                    <span className="p-float-label">
                        <InputText className="w-100" id="assunto" name="assunto" value={assunto} onChange={(e) => setAssunto(e.target.value)} required/>
                        <label htmlFor="assunto">ASSUNTO</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 mb-2">
                    <span className="p-float-label">
                        <Dropdown className="w-100" id="destinatario" name="destinatario" options={SelectItemsDESTINATARIOS} onChange={(e) => setDestinatario(e.target.value)} placeholder="Selecione"/>
                        <label className="control-label" htmlFor="destinatario">DESTINATÁRIO</label>
                    </span>
                </div>
                <div className="col-md-12 form-floating mb-2">
                    <CKEditor
                        editor={ ClassicEditor }
                        config={{ toolbar: [ 'bold', 'italic', '|', 'numberedList', 'bulletedList'] /*'underline', 'strikethrough', 'textColor'*/ }}
                        data={mensagem}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "220px",
                                    editor.editing.view.document.getRoot()
                                );
                            });
                            //console.log( 'Editor is ready to use!', editor );
                        } }
                        // onChange={ ( event, editor ) => {
                        //     const data = editor.getData();
                        //     setMensagem(data);
                        // } }

                    />
                </div>
                <div className="mt-3">
                    <Button variant="success" onClick={() => EnviarMensagem()} className="w-100">ENVIAR</Button>
                </div>
            </div>
        </div>
    </>
}

export default Contato;