import React, { createContext } from 'react';
import io from 'socket.io-client';

// funcoes proprias
//import Api from '../Api';

export const SocketContext = createContext();

export const SocketProvider = ({children}) => {

    //const socket = io.connect('wss://discos.com.br/socketserver');
    //const socket = io.connect('https://discos.com.br:3001');
    //const socket = io.connect('https://ws.discos.com.br');
    //const socket = io.connect('https://discos.com.br/ws');
    //const socket = io.connect('wss://discos.com.br/ws');
    const socket = io.connect('wss://discos.com.br');

    // useEffect(() => {
    // }, []);

    // const logout = () => {
    // };

    return (
        <SocketContext.Provider value={{socket}}>
            { children }
        </SocketContext.Provider>
    )
}