import React from 'react';
import Estrelas from './Estrelas';
import Formata from './Formata';
import IfComponent from './IfComponent';
import NumberFormat from "react-number-format";

function DiscoHeader(props) {
    return <>
        <IfComponent condicional={props.DIS_CODIGO>0}>
        <div className="row">  
            <div className={props.imagens.length>0?"col-12 bg-success bg-opacity-10":"col-12 bg-success bg-opacity-10"}>
                <div className="d-flex align-items-stretch">      
                    <div><img data-fancybox="avatar" src={props.DIF_ARQUIVO} style={{maxWidth: '120px'}} alt={props.DIS_CODIGO} className="rounded-5 img-fluid me-2 p-0 border border-light border-3 shadow-sm"/></div>
                    <div className="row gx-2">
                        <div className="col-4 text-end mt-2">ID:</div>
                        <div className="col-8 mt-1"><span className="fw-bold fs-6 badge bg-success bg-opacity-75 rounded-pill"><Formata formato="000000">{props.DIS_CODIGO}</Formata></span></div>
                        <div className="col-4 text-end">Ano:</div><div className="col-8 fw-bold">{props.DIS_ANO}</div>
                        <div className="col-4 text-end">Tamanho:</div><div className="col-8 fw-bold">{props.DIS_TAMANHO}"</div>
                        <div className="col-4 text-end">Raridade:</div><div className="col-8"><Estrelas qtde={props.DIS_RARIDADE} /></div>
                        {/* <div className="col-4 text-end">Valor:</div><div className="col-8 fw-bold text-danger"><Formata formato="$ 0.00">{props.DIS_VALOR}</Formata></div> */}
                        <div className="col-4 text-end">Valor:</div><div className="col-8 fw-bold text-danger">
                        <NumberFormat 
                            value={parseFloat(props.DIS_VALOR)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={false}
                            allowNegative={true}
                        /></div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <p className="lead fw-bold text-center lh-1 mt-3 m-0">{props.DIS_TITULO}</p>
                <p className="fs-5 fw-light text-center lh-1 mt-1 text-success">{props.ART_NOME}</p>
            </div>
        </div>    
        </IfComponent>
    </>
}

export default DiscoHeader;