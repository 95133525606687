import React, { useState, useEffect, useRef } from 'react';
//import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import BarraInferior from '../componentes/BarraInferior';
import IfComponent from '../componentes/IfComponent';
//import { SocketContext } from '../contextos/Socket';
import Api from '../Api';

function Chats() {
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    //const { socket } = useContext(SocketContext);

    function getChats(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
                formData.set('op', 'listarchats');
                formData.set('pagina', novaPagina);
                formData.set('pesquisa', pesquisa);
            
            Api.post('chats.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch((response) => {
                setCarregando(false);
                console.log('CATCH: '+JSON.stringify(response.data));
            }); 
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {
        if (scrollRatio > 0) {
            getChats(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]); 

    // useEffect(() => {
    //     console.log('effect socket')
    //     socket.on('msgnaolida', (data) => {
    //         if (data.erro==='N') {
    //             setNaotagem((lista) => [...lista, data.msg]);
    //         } else {
    //             console.log('ERRO: ',data.msg);
    //         }
    //     });
   
    // },[socket]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        getChats(0,false,true);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone">&nbsp;</div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CONVERSAS</p></div>
                        <div className="link-icone">&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>        

        <IfComponent condicional={ listagem.length > 0}>
            <div className="container limit-width pb-2"></div>
            <div className='container-fluid limit-width mt-5 py-5'>
            {
                listagem.map(chat => (
                    <div className="card border-0 bg-transparent" key={chat.CHA_CHATID}>
                        <div className="row border-bottom">
                            <div className="col-2 m-0 p-1"><img src={chat.CHA_ME===chat.CHA_USER1?chat.USU_FOTO2:chat.USU_FOTO1} className="rounded-circle img-fluid position-relative" alt='' /></div>
                            <div className="col-9 m-0 p-1 d-flex align-items-center">
                                <div className="row text-truncate">
                                    <div className="col-12"><Link to={'/chats/'+chat.CHA_CHATID} className="link-preto stretched-link"><b>{chat.CHA_ME===chat.CHA_USER1?chat.USU_NOME2:chat.USU_NOME1}</b></Link></div>
                                    <div className="col-12 text-truncate small text-muted"><i className="fa fa-fw fa-check text-primary"></i><i>{chat.CHM_MENSAGEM}</i></div>
                                </div>
                            </div>
                            <div className="col-1 m-0 p-0 small d-flex align-items-center">
                                <div className='text-end w-100 me-2'>
                                    {
                                        chat.CHM_NAOLIDAS > 0 ? <span className="badge bg-danger rounded-pill">{chat.CHM_NAOLIDAS}</span>:''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    // <div className="container-fluid limit-width border-0 border-bottom bg-transparent d-flex py-1" key={chat.CHA_CHATID}>
                    //     <div className="col-2"><img src={chat.CHA_ME===chat.CHA_USER1?chat.USU_FOTO2:chat.USU_FOTO1} className="rounded-circle img-fluid position-relative" alt='' /></div>
                    //     <div className="p-2 flex-grow-1 d-flex align-items-center text-truncate">
                    //         <div className="row text-truncate">
                    //             <div className="col-12"><Link to={'/chats/'+chat.CHA_CHATID} className="link-preto stretched-link"><b>{chat.CHA_ME===chat.CHA_USER1?chat.USU_NOME2:chat.USU_NOME1}</b></Link></div>
                    //             <div className="col-12 text-truncate small text-muted"><i className="fa fa-fw fa-check text-primary"></i><i>{chat.CHM_MENSAGEM}</i></div>
                    //         </div>
                    //     </div>
                    //     <div className="d-flex align-items-center border"><span className="badge bg-danger rounded-pill">123</span></div>

                    // </div>
                ))
            }
            </div>
        </IfComponent>           
        
        <div className="container limit-width" ref={scrollObserver} id="sentinela"></div>

        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>

        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-comments"></i></span><br/>Nenhuma conversa encontrada!</p>
            </div>                    
        </IfComponent>

        <BarraInferior active="chats"/>
    </>
}

export default Chats;