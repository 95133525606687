import React from 'react';

function Estrelas(props) {
    var i=0;
    const estrelas=[0,0,0,0,0];
    for (i=0; i<props.qtde; i++) {
      estrelas[i]=1;
    }

    return (
        <>
            { estrelas.map( (full,idx) => full===1 ? <i key={idx} className='fas fa-fw fa-star text-warning starfull'></i>:<i key={idx} className='far fa-fw fa-star text-warning'></i> )}
        </>
    );
}

export default Estrelas;