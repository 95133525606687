import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { InputText } from 'primereact/inputtext';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import BarraInferior from '../componentes/BarraInferior';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Generos() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const navigate=useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ generoSelecionado, setGeneroSelecionado ] = useState(null);
    const [ GEN_CODIGO, setGEN_CODIGO ] = useState('');
    const [ GEN_DESCRICAO, setGEN_DESCRICAO ] = useState('');
    
    function listarGeneros(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
            
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);

            Api.post('generos.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }
                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }
    
    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });

        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarGeneros(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    useEffect(() => {
        console.log('efeito',generoSelecionado);
        if (generoSelecionado) {
            setGEN_CODIGO(generoSelecionado.GEN_CODIGO);
            setGEN_DESCRICAO(generoSelecionado.GEN_DESCRICAO);
            setShowModal(true);
        } else {
            setGEN_CODIGO('');
            setGEN_DESCRICAO('');
            setShowModal(false);
        }
    },[generoSelecionado]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarGeneros(0,false,true);
    }

    function AtualizarRegistro() {
        setGeneroSelecionado('');
        const op=GEN_CODIGO>0?'atualizar':'inserir';
        var bodyFormData = new FormData();
        bodyFormData.set('op', op);
        bodyFormData.set('GEN_CODIGO', GEN_CODIGO);
        bodyFormData.set('GEN_DESCRICAO', GEN_DESCRICAO);

        Api.post('generos.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                //TODO: atualizar a listagem.
                Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                setPagina(0);
                setFinal(false);
                listarGeneros(0,false,true);
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });
    }   

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
      
    const trailingActions = ({genero}) => (
        <TrailingActions>
            <SwipeAction destructive={false} onClick={() => {
                Swal.fire({
                    position: 'center',
                    icon: 'question',
                    title: 'Confirma Exclusão?',
                    text: 'Tem certeza que deseja excluir '+genero.GEN_DESCRICAO+'?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, quero!',
                    cancelButtonText: 'Nãããoooooo',
                    confirmButtonColor: '#212529'
                }).then((result) => {
                    if (result.isConfirmed) {
                        var bodyFormData = new FormData();
                        bodyFormData.set('op', 'excluir');
                        bodyFormData.set('GEN_CODIGO', genero.GEN_CODIGO);
                        Api.post('generos.php',bodyFormData).then((response) => {
                            if (response.data.erro==='N') {
                                Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                                setPagina(0);
                                setFinal(false);
                                listarGeneros(0,false,true);
                            } else {
                                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                            }
                        }).catch(({response}) => {
                            console.log('catch: '+JSON.stringify(response));
                        });
                    }
                });
            }}>
                <div className="d-flex align-items-center bg-danger text-light text-center p-3" style={{cursor: 'pointer'}}><i className="fa fa-fw fa-trash"></i> Excluir</div>
            </SwipeAction>
        </TrailingActions>
    );

    function btnNovo() {
        setGeneroSelecionado({ GEN_CODIGO: '', GEN_DESCRICAO: '' });
        setShowModal(true);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='' onClick={() => navigate(-1)}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">GÊNEROS</p></div>
                        <div className="link-icone"><Link to='' onClick={btnNovo}><i className="fa fa-2x fa-fw fa-edit"></i></Link></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
        <div className="container limit-width mt-5 py-5">
            <SwipeableList fullSwipe={false} type={ListType.IOS}>
  
            {                     
                listagem.map(genero => (
                    // <SwipeableListItem  key={genero.GEN_CODIGO} leadingActions={leadingActions( { genero })} trailingActions={trailingActions({ genero })}>
                    <SwipeableListItem  key={genero.GEN_CODIGO} trailingActions={trailingActions({ genero })}>
                        <div className="card border-0 border-bottom bg-transparent w-100">
                            <div className="row">
                                <div className="col-12 ms-3 p-2">
                                    <div><Link to="" onClick={() => setGeneroSelecionado(genero)} className="link-preto stretched-link swipe-element">{genero.GEN_DESCRICAO}</Link></div>
                                </div>
                            </div>
                        </div>
                    </SwipeableListItem>                
                ))
            }   
            </SwipeableList>
        </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-sync-alt"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-microphone"></i></span><br/>Nenhum gênero encontrado!</p>
            </div>                    
        </IfComponent>
        <BarraInferior active="generos"/>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height" closeButton>
                <Modal.Title><h5 className="modal-title">Cadastro de Gêneros</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row mb-0 mt-0 gx-2">
                    <div className="col-12 form-floating mb-2">
                        <span className="p-float-label">
                            <InputText className="w-100" maxLength="120" id="GEN_DESCRICAO" name="GEN_DESCRICAO" value={GEN_DESCRICAO} onChange={(e) => setGEN_DESCRICAO(e.target.value.toUpperCase())} />
                            <label htmlFor="GEN_DESCRICAO">GÊNERO</label>
                        </span>                          
                        <input type="hidden" name="GEN_CODIGO" id="GEN_CODIGO" value={generoSelecionado && generoSelecionado.ART_CODIGO} />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light py-0 limit-height'>
                <Button variant="secondary" onClick={() => setGeneroSelecionado('')}>Cancelar</Button>
                <Button variant="success" onClick={() => {AtualizarRegistro();}}><i className="fa fa-fw fa-check"></i> Salvar</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default Generos;