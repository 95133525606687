import React from 'react';

function Totalizadores(props) {
    return <>
        <div className="row g-0 mt-2 mb-3 text-dark bg-light border border-success rounded-3">
            <div className="col-3 text-center">
                <p className="fw-bold lh-1 m-0 mt-1 fs-6">{props.qtdeArtistas}</p>
                <p className="small lh-1 m-0 my-1 text-muted text-trucate">Artistas</p>
            </div>
            <div className="col-3 text-center">
                <p className="fw-bold lh-1 m-0 mt-1 fs-6">{props.qtdeGeneros}</p>
                <p className="small lh-1 m-0 my-1 text-muted text-truncate">Gêneros</p>
            </div>
            <div className="col-3 text-center">
                <p className="fw-bold lh-1 m-0 mt-1 fs-6">{props.qtdeDiscos}</p>
                <p className="small lh-1 m-0 my-1 text-muted text-truncate">Discos</p>
            </div>
            <div className="col-3 text-center">
                <p className="fw-bold lh-1 m-0 mt-1 fs-6">{props.valorDiscos}</p>
                <p className="small lh-1 m-0 my-1 text-muted text-truncate">Valor</p>
            </div>
        </div>
    </>
}

export default Totalizadores;