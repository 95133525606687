import React from 'react';
import { Link } from 'react-router-dom';

function BarraInferior(props) {
    return <>
        <div className="fixed-bottom bg-dark p-1">
            <div className="container-fluid limit-width">
                <div className="d-flex justify-content-between">
                    <div className="p-2 link-icone"><Link className={props.active==="vitrine"?"ativo":""} to="/vitrine"><i className="fa fa-2x fa-fw fa-home"></i></Link></div>
                    <div className="p-2 link-icone"><Link className={props.active==="acervo"?"ativo":""} to="/acervo"><i className="fa fa-2x fa-fw fa-archive"></i></Link></div>
                    <div className="p-2 link-icone"><Link className={props.active==="artistas"?"ativo":""} to="/artistas"><i className="fa fa-2x fa-fw fa-microphone"></i></Link></div>
                    <div className="p-2 link-icone"><Link className={props.active==="generos"?"ativo":""} to="/generos"><i className="fa fa-2x fa-fw fa-music"></i></Link></div>
                    <div className="p-2 link-icone"><Link className={props.active==="chats"?"ativo":""} to="/chats"><i className="fa fa-2x fa-fw fa-comments"></i></Link></div>
                    <div className="p-2 link-icone"><Link className={props.active==="profile"?"ativo":""} to="/profile"><i className="fa fa-2x fa-fw fa-bars"></i></Link></div>
                </div>
            </div>
        </div>
    </>
}

export default BarraInferior;