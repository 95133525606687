import React from "react";
import Dropzone from "react-dropzone";
import './Upload.css';
// import { DropContainer, UploadMessage } from "./styles";

function Upload(props) {
    //console.log(props)
    const dragActive = ' border border-success ';
    const dragReject = ' border border-danger ';
    const { onUpload } = props;

    const renderDragMessage = (isDragActive, isDragReject) => {
        if (!isDragActive) {
            return <p className='text-center m-0 p-0 text-secondary'>Arraste arquivos aqui, ou clique para selecionar...</p>
        }
        if (isDragReject) {
            return <p className='text-center m-0 p-0 text-danger'>Arquivo não suportado</p>
        }   
        return <p className='text-center m-0 p-0 text-success'>Solte os arquivos aqui...</p>
    };
    

    return <>
        {/* <Dropzone accept={props.accept} onDrop={acceptedFiles => console.log(acceptedFiles)} style={{margin: '0px', padding: '0px'}}> */}
        {/* <Dropzone accept={props.accept} onDrop={acceptedFiles => console.log(acceptedFiles)}> */}
        <Dropzone accept={props.accept} onDropAccepted={onUpload}>
            {({getRootProps, getInputProps, isDragActive, isDragReject }) => (
                
                <div className={'caixaupload d-flex justify-content-center align-items-center '+(isDragActive && dragActive)+(isDragReject && dragReject)} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {renderDragMessage(isDragActive,isDragReject)}
                </div>
            )}
        </Dropzone>
    </>
}

export default Upload;