import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import BarraInferior from '../componentes/BarraInferior';
import Totalizadores from '../componentes/Totalizadores';
import Api from '../Api';

function Profile() {
    const { logout } = useContext(AuthContext);
    const navigate=useNavigate();
    const [ resumo, setResumo ] = useState({});

    useEffect(() => {
        var formData = new FormData();
        formData.set('op', 'resumo');

        Api.post('profile.php',formData).then((response) => {
            if (response.data.erro==='N') {
                //console.log(JSON.stringify(response.data.msg[0]));
                setResumo(response.data.msg[0]);
            } else {
                logout();
                Swal.fire({
                    title: 'Ah nãooooo!',
                    text: response.data.msg,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#212529'
                });
            }
        }).catch(({response}) => {
            logout();
            //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        });        
    },[]);

    function Sair () {
        logout();
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to="" onClick={() => navigate(-1)}><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <span className="position-absolute top-50 start-50 translate-middle text-light fw-bolder">SEU PERFIL</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid limit-width">
        
            <div className="row mt-5">
                <div className="col-12 d-flex justify-content-center mt-2"><a href="/profilefoto"><img src={resumo.USU_FOTOPERFIL} style={{maxWidth: '140px'}} alt="foto" className="rounded-circle img-fluid m-0 p-0 border border-light border-3 shadow-sm"/></a></div>
                <div className="col-12 d-flex justify-content-center">
                    <p className="fw-lighter fs-3 m-0 p-0">{resumo.USU_NOME}</p>
                </div>
                <div className="col-12 px-1 m-0 d-flex justify-content-center">
                    <p className="mb-0"><b>{resumo.USU_EMAIL}</b></p>
                </div>
            </div>
            <Totalizadores qtdeArtistas={resumo.QTDEARTISTAS} qtdeGeneros={resumo.QTDEGENEROS} qtdeDiscos={resumo.QTDEDISCOS} valorDiscos={resumo.VALORDISCOS} />
            <p className="mb-0 fw-bold">SEU PERFIL</p>
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/profilefoto' className="link-preto stretched-link">Foto do Perfil</Link></li>
                <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/profileupdate' className="link-preto stretched-link">Editar Perfil</Link></li>
                <li className="list-group-item d-flex justify-content-between align-items-center"><a className='link-preto stretched-link' href="/alteraremail.php">Alterar Email</a></li>
                <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/alterarsenha' className="link-preto stretched-link">Alterar Senha</Link></li>
            </ul>

            <p className="mb-0 fw-bold">OUTROS</p>
            <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center"><Link to="/contato" className="link-preto stretched-link">Contato com o suporte</Link></li>
                <li className="list-group-item d-flex justify-content-between align-items-center"><Link to="/termos" className="link-preto stretched-link">Termos de uso do serviço</Link></li>
            </ul>
            <button className="btn btn-lg w-100 btn-primary mt-5" onClick={Sair}>DESCONECTAR</button>          
            <p className="mb-5 mt-2 text-muted">versão: 1.0 - Copyright 2021&copy;</p>
        </div>
        <BarraInferior active="profile"/>
    </>
}

export default Profile;