import React, { useEffect, useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
//import AsyncStorage from '@react-native-async-storage/async-storage';

// funcoes proprias
import Api from '../Api';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
 
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState(null);
    const [usuarioNome, setUsuarioNome] = useState('');
    const [usuarioEmail, setUsuarioEmail] = useState('');
    const [usuarioStatus, setUsuarioStatus] = useState('');
    const [logado, setLogado] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [carregando, setCarregando] = useState(true);

    useEffect(() => {
        const usuarioStorage = localStorage.getItem('usuario');
        const tokenStorage = localStorage.getItem('token');
        
        if (usuarioStorage && tokenStorage) {
            const jwtDecoded = jwt_decode(tokenStorage);
            setUsuario(JSON.parse(usuarioStorage));
            setLogado(true);
            setUsuarioNome(jwtDecoded.nome);
            setUsuarioEmail(jwtDecoded.email);
            setUsuarioStatus(jwtDecoded.status);
            Api.defaults.headers.common['Authorization'] = `Bearer ${tokenStorage}`;
        }
        setCarregando(false);
    }, []);

    const setarToken = (response, login, senha) => {
        const token = response.data.usuario.USU_TOKEN;
        const nome = response.data.usuario.USU_NOME;
        const email = response.data.usuario.USU_EMAIL;
        const jwtDecoded = jwt_decode(response.data.usuario.USU_TOKEN);
        localStorage.setItem('usuario', JSON.stringify({nome, email, login, senha}));
        localStorage.setItem('token',token);
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUsuario({ nome, email, login, senha });
        setUsuarioNome(jwtDecoded.nome);
        setUsuarioEmail(jwtDecoded.email);
        setUsuarioStatus(jwtDecoded.status);
    }

    async function logar(login, senha) {
        if (!login || !senha) {
            setMensagem("Informe todos os campos.");
            return
        }
        var formData = new FormData();
        formData.set('op', 'login');
        formData.set('login', login);
        formData.set('senha', senha);

        try {
            const response = await Api.post('login.php',formData);
            if (response.data.erro==="S") {
                setMensagem(response.data.msg);
                setLogado(false);
                setUsuarioNome('');
                setUsuarioEmail('');
                setUsuarioStatus('');
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                        //navigate('/login');
            } else {
                setarToken(response, login, senha);
                setLogado(() => true);
                setUsuarioNome(response.data.usuario.USU_NOME);
                setUsuarioEmail(response.data.usuario.USU_EMAIL);
                setUsuarioStatus(response.data.usuario.USU_STATUS);
                setMensagem('');
                //navigate('/');
                    // if (response.data.usuario.USU_STATUS==='I') {
                    //     navigate('/confirmarcadastro');
                    // }
                    // if (response.data.usuario.USU_STATUS==='A') {
                    //     navigate('/vitrine');
                    // } else {
                    //    // navigate('/login');
                    // }
            }
        } catch (error) {
            console.log('Erro:',error)
        }
    };

    const logout = () => {
        Api.defaults.headers.Authorization = null;
        Api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('usuario');
        localStorage.removeItem('token');
        setUsuario(null);
        setLogado(false);
        setUsuarioNome('');
        setUsuarioEmail('');
        setUsuarioStatus('');
        //navigate('/login', {replace: true});
        window.location.reload();
    };

    async function reenviar(login) {
        var formData = new FormData();
        formData.set('op', 'reenviar');
        formData.set('login', usuarioEmail);
        try {
            const response = await Api.post('login.php',formData);
            if (response.data.erro==="S") {
                console.log('CODIGO ERRADO - '+response.data.msg)
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            } else {
                console.log('USUARIO OK - '+JSON.stringify(response.data));
                Swal.fire({title: 'Confirma lá!', text: response.data.msg, icon: 'info', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            console.log('Erro:',error)
        }
    }

    async function confirmar(login, confirmacao) {
        var formData = new FormData();
        formData.set('op', 'confirmar');
        formData.set('login', login);
        formData.set('codigo', confirmacao);          
        try {
            const response = await Api.post('login.php',formData);
            if (response.data.erro==="S") {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            } else {
                setarToken(response, login, usuario.senha);
                setLogado(true);
                setUsuarioNome(response.data.usuario.USU_NOME);
                setUsuarioEmail(response.data.usuario.USU_EMAIL);
                setUsuarioStatus(response.data.usuario.USU_STATUS);
                setMensagem('');
                Swal.fire({title: 'Ebaaaa!', text: response.data.msg, icon: 'success', confirmButtonText: 'OK', confirmButtonColor: '#212529', 
                    didClose: () => {
                        navigate('/vitrine');
                    }
                });
            }
        } catch (err) {
            console.log('INICIO BLOCO CATCH', err);
        }   
    }

    async function resetar(login) {
        var formData = new FormData();
        formData.set('op', 'resetar');
        formData.set('login', login);
        try {
            const response = await Api.post('login.php',formData);
            if (response.data.erro==='S') {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            } else {
                Swal.fire({title: 'Ebaaaa!', text: response.data.msg, icon: 'success', confirmButtonText: 'OK', confirmButtonColor: '#212529', 
                    didClose: () => {
                        navigate('/login');
                    }
                });
            }
        } catch (err) {
            console.log('INICIO BLOCO CATCH', err);
        }
    }

    return (
        // <AuthContext.Provider value={{logado: !!usuario, usuario, carregando, mensagem, logar, logout}}>
        <AuthContext.Provider value={{logado, usuario, usuarioNome, usuarioEmail, usuarioStatus, carregando, mensagem, logar, logout, reenviar, confirmar, resetar}}>
            { children }
        </AuthContext.Provider>
    )
}