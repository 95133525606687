import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';

// funcoes proprias.
import { AuthContext } from '../contextos/Auth';
import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';

function ProfileUpdate() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ apresentacao, setApresentacao ] = useState('');
    const [ nome, setNome ] = useState('');

    function atualizarProfile() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'atualizarprofile');
        bodyFormData.set('nome', nome);
        bodyFormData.set('apresentacao', apresentacao);

        Api.post('usuarios.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({
                    title: 'Ebaaaaaa!',
                    text: 'Perfil atualizado!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/profile');
                    }
                });
            } else {
                Swal.fire({
                    title: 'Ah nãooooo!',
                    text: response.data.msg,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#212529'
                });
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });  
    }

    function getProfile() {
        const bodyFormData=new FormData();
        bodyFormData.set('op', 'getprofile');

        Api.post('usuarios.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                setApresentacao(response.data.msg[0].USU_APRESENTACAO);
                setNome(response.data.msg[0].USU_NOME);
            } else {
                logout();
            }
        }).catch((response) => {
            //console.log(JSON.stringify(response));
        });
    }

    useEffect(() => {        
        getProfile();
    },[]);

    return <>
        <BarraSuperior titulo='EDITAR PERFIL' linkleft='voltar' linkright='' />
        <div className="container-fluid limit-width py-5">
            <div className="row mt-3 mb-3 gx-2">
                <div className="col-lg-12 col-md-12 mb-2 p-inputtext-sm">
                    <span className="p-float-label">
                        <InputText className="w-100" maxLength="120" id="nome" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} required/>
                        <label htmlFor="nome">NOME COMPLETO</label>
                    </span>                          
                </div>
                <div className="col-md-12 form-floating mb-2">
                    <CKEditor
                        editor={ ClassicEditor }
                        config={{ toolbar: [ 'bold', 'italic', '|', 'numberedList', 'bulletedList'] /*'underline', 'strikethrough', 'textColor'*/ }}
                        data={apresentacao}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    "height",
                                    "250px",
                                    editor.editing.view.document.getRoot()
                                );
                            });
                            //console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setApresentacao(data);
                        } }
                        onBlur={ ( event, editor ) => {
                            //console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            //console.log( 'Focus.', editor );
                        } }
                    />
                </div>

                <div className="">
                    <Button variant="success" onClick={() => atualizarProfile()} className="w-100">ENVIAR</Button>
                </div>
            </div>
        </div>
    </>
}

export default ProfileUpdate;