import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import { AuthContext } from '../contextos/Auth';
import BarraInferior from '../componentes/BarraInferior';
import AcervoCard from '../componentes/AcervoCard';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Vitrine() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    //const [ registroSelecionado, setRegistroSelecionado ] = useState(null);
    const [ desejo, setDesejo ] = useState(2);
    const [ desejoClass, setDesejoClass ] = useState('far fa-2x fa-fw fa-heart');
    
    function listarVitrine(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('pesquisa', pesquisa);
                formData.set('desejo', desejo);
                formData.set('acervo', false);
            Api.post('vitrine.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch((response) => {
                setCarregando(false);
                console.log('CATCH: '+JSON.stringify(response.data));
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {
        if (scrollRatio > 0) {
            listarVitrine(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]); 

    useEffect(() => {
        //inicialmente setamos o valor 2 para desejo para ele nao executar o listarVitrine no inicio.
        if (desejo !== 2) { 
            setPagina(0);
            setFinal(false);
            listarVitrine(0,false,true);        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[desejo]); 

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarVitrine(0,false,true);
    }

    function onDesejoClick() {
        //setando "desejo", dispara o useEffect dele.
        if (desejo===0 || desejo===2) {
            setDesejo(1);
            setDesejoClass('fas fa-2x fa-fw fa-heart text-danger');
        } else {
            setDesejo(0);
            setDesejoClass('far fa-2x fa-fw fa-heart');            
        }
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone">&nbsp;</div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">VITRINE DE DISCOS</p></div>
                        <div className="link-icone"><Link to="" onClick={onDesejoClick}><i className={desejoClass}></i></Link></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                        {/* <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { setFinal(false); setPagina(0); setListagem([]); findGeneros() }} >Filtrar</button><input type="hidden" name="op" value="localizar"/></div> */}
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
            <div className="container limit-width pb-2"></div>
            <div className="container limit-width mt-5 py-5">
                {                     
                    listagem.map(vitrine => (
                        // <AcervoCard onClick={console.log('clicou acervoaaa')}
                        <AcervoCard 
                            key={vitrine.DIS_CODIGO}
                            acervo={false}
                            ART_NOME={vitrine.ART_NOME}
                            DES_DISCO={vitrine.DES_DISCO}
                            DIF_ARQUIVO={vitrine.DIF_ARQUIVO}
                            DIS_ANO={vitrine.DIS_ANO}
                            DIS_CODIGO={vitrine.DIS_CODIGO}
                            DIS_TITULO={vitrine.DIS_TITULO}
                            DIS_RARIDADE={vitrine.DIS_RARIDADE}
                            DIS_VALOR={vitrine.DIS_VALOR}
                            GEN_DESCRICAO={vitrine.GEN_DESCRICAO}
                            PAI_NOMEBR={vitrine.PAI_NOMEBR}
                            USU_NOME={vitrine.USU_NOME}
                            PATH = 'vitrine' 
                        />                   
                    ))
                }   
            </div>
        </IfComponent>           
        <div className="container limit-width bg-danger" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Nenhum vinil encontrado!</p>
            </div>                    
        </IfComponent>
        <BarraInferior active="vitrine"/>
    </>
}

export default Vitrine;