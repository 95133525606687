import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

// funcoes proprias.
import { AuthContext } from '../contextos/Auth';
import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';

function ProfileFoto() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ avatar, setAvatar ] = useState('');
    const [ arquivo, setArquivo ] = useState('');

    function atualizarAvatar(evento) {
        //const imagedata = evento.target.files[0];
        const imagedata = document.querySelector('input[type="file"]').files[0];
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'atualizarfoto');
        bodyFormData.append('arquivo', imagedata);

        Api.post('usuarios.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({
                    title: 'Ebaaaaaa!',
                    text: 'Perfil atualizado!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/profile');
                    }
                });
                getAvatar();
            } else {
                Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response.data));
        });  
    }

    function getAvatar() {
        const bodyFormData=new FormData();
        bodyFormData.set('op', 'getavatar');

        Api.post('usuarios.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                setAvatar(response.data.msg);
            } else {
                logout();
            }
        }).catch((response) => {
            console.log(JSON.stringify(response.data));
        });
    }

    useEffect(() => {        
        getAvatar();
    },[]);

    return <>
        <BarraSuperior titulo='FOTO DO PERFIL' linkleft='voltar' lingright='' />
        <div className="container-fluid limit-width py-5">
            <div className="row mt-3 mb-3 gx-2">
                <div className="col-12 d-flex justify-content-center mt-2"><img src={avatar} style={{maxWidth: '140px'}} alt="foto" className="rounded-circle img-fluid m-0 p-0 border border-light border-3 shadow-sm" /></div>
                <p>Para alterar sua foto, selecione o arquivo .JPG e clique em enviar<br/>Aguarde até o fim do envio.</p>
                <div className="col-12 mb-5" data-provides="fileinput">
                    <div className="input-group">
                        <input type="file" className="form-control form-floating" id="arquivo" name="arquivo" onChange={(e) => setArquivo(e.target.value)} value={arquivo}/>
                    </div>
                </div>              
                <div className="">
                    <Button variant="success" onClick={(e) => atualizarAvatar(e)} className="w-100">ENVIAR</Button>
                </div>
            </div>
        </div>
    </>
}

export default ProfileFoto;