import React from 'react';
import Estrelas from '../componentes/Estrelas';

function Desejo(props) {
    return <>
        {
            props.DES_DISCO===0?
                <i className="far fa-fw fa-heart text-muted"></i>:
                <i className="fas fa-fw fa-heart text-danger"></i>
        }
    </>
}

function AcervoCard(props) {
    return <>
        <div className="card border-0 border-bottom bg-transparent">
            <div className="row">
                <div className="col-3 m-0 p-1"><img src={props.DIF_ARQUIVO} className="rounded img-fluid position-relative" alt="" /></div>
                <div className="col-9 m-0 p-1 d-flex align-items-center">
                    <div className="row text-truncate">
                        <div className="col-12 text-truncate fw-bolder fs-6 m-0"><a className="link-preto stretched-link" href={'/disco/'+props.DIS_CODIGO}>{props.DIS_TITULO}</a></div>
                        <div className="col-12 text-truncate text-primary mt-0">{props.ART_NOME}</div>                   
                        {   
                            props.acervo===true? 
                                <div className="col-12 text-truncate small text-muted">{props.DIS_ANO} - {props.GEN_DESCRICAO} - {props.PAI_NOMEBR}</div>:
                                <div className="col-12 text-truncate small text-muted">{props.USU_NOME}</div>
                        }
                        <div className="col-12 text-truncate small text-muted d-flex justify-content-between">
                            <span>
                                <Estrelas qtde={props.DIS_RARIDADE} />
                                { props.acervo===false ? <Desejo DES_DISCO={props.DES_DISCO} /> : null }
                            </span>
                            <span className="fw-bold fs-6 text-success">
                                {props.DIS_VALOR}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
} 

export default AcervoCard;