import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider, AuthContext } from './contextos/Auth';
import { SocketProvider } from './contextos/Socket';

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

//paginas.
import Acervo from './paginas/Acervo';
import AlterarSenha from './paginas/AlterarSenha';
import Artistas from './paginas/Artistas';
import CriarConta from './paginas/CriarConta';
import Chats from './paginas/Chats';
import ChatsView from './paginas/ChatsView';
import ConfirmarCadastro from './paginas/ConfirmarCadastro';
import Contato from './paginas/Contato';
import Disco from './paginas/Disco';
import DiscoFotos from './paginas/DiscoFotos';
import DiscoFotosEnviar from './paginas/DiscoFotosEnviar';
import ErrorPage from './paginas/ErrorPage';
import Generos from './paginas/Generos';
import Profile from './paginas/Profile';
import ProfileFoto from './paginas/ProfileFoto';
import ProfileId from './paginas/ProfileId';
import ProfileUpdate from './paginas/ProfileUpdate';
import Vitrine from './paginas/Vitrine';
import Login from './paginas/Login/Login';
import Termos from './paginas/Termos';
import Reset from './paginas/Reset';
//import 'bootstrap/dist/css/bootstrap.min.css';

//const socket = io.connect('http://localhost:3001');

function App() {
    //console.log('APP - inicio.')

    const Privado = ({ children }) => {
        const { logado, usuarioStatus, carregando } = useContext(AuthContext);
        //console.log('APP - Privado '+logado, 'status: ', usuarioStatus)

        if (carregando) {
            return <>
                <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-spin fa-sync-alt"></i></span><br/>Carregando...</p>
                </div>  
            </>
        }

        if (usuarioStatus==='I') {
            return <Navigate to='/confirmarcadastro' />
        }

        if (!logado) {
            return <Navigate to="/login" replace={true}/>
        }

        return children;
    };

    const SoLogado = ({ children }) => {
        const { logado } = useContext(AuthContext);
        if (!logado) {
            return <Navigate to="/login" />
        }
        return children;
    };


    return (
        <Router>
            <AuthProvider>
                <SocketProvider>
                    <Routes>
                        <Route exact path='/' element={<Navigate replace to="/vitrine" />} /> 
                        <Route exact path='/acervo' element={<Privado><Acervo /></Privado>} />
                        {/* <Route exact path='/acervo/:id' element={<Privado><Acervo /></Privado>} /> */}
                        <Route exact path='/alterarsenha' element={<Privado><AlterarSenha /></Privado>} />
                        <Route exact path='/artistas' element={<Privado><Artistas /></Privado>} />     
                        <Route exact path='/chats' element={<Privado><Chats /></Privado>} />
                        <Route exact path='/chats/:id' element={<Privado><ChatsView /></Privado>} />
                        <Route exact path='/confirmarcadastro' element={<SoLogado><ConfirmarCadastro /></SoLogado>} />
                        <Route exact path='/contato' element={<Privado><Contato /></Privado>} />
                        <Route exact path='/criarconta' element={<CriarConta />} />
                        <Route exact path='/disco/:id' element={<Privado><Disco /></Privado>} />
                        <Route exact path='/discofotos/:id' element={<Privado><DiscoFotos /></Privado>} />
                        <Route exact path='/discofotosenviar/:id' element={<Privado><DiscoFotosEnviar /></Privado>} />
                        {/* <Route path='/disco' element={<Privado><Disco /></Privado>} /> */}
                        {/* <Route exact path='/disco' element={<Disco />} /> */}
                        <Route exact path='/generos' element={<Privado><Generos /></Privado>} />
                        <Route exact path='/login' element={<Login />} />
                        <Route exact path='/profile' element={<Privado><Profile /></Privado>} />
                        <Route exact path='/profile/:id' element={<Privado><ProfileId /></Privado>} />
                        <Route exact path='/profilefoto' element={<Privado><ProfileFoto /></Privado>} />
                        <Route exact path='/profileupdate' element={<Privado><ProfileUpdate /></Privado>} />
                        <Route exact path='/reset' element={<Reset />} />
                        <Route exact path='/termos' element={<Termos />} />
                        <Route exact path='/vitrine' element={<Privado><Vitrine /></Privado>} />
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </SocketProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
