import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// funcoes proprias.
import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';

function Termos() {
    const [ bloco, setBloco ] = useState('');
    
    function verBloco() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'ver');
        bodyFormData.set('codigo', 4);
        
        Api.post('paginas.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                setBloco(response.data.msg.BLO_CONTEUDO);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }

    useEffect(() => {
        verBloco();
    },[]);

    return <>
        <BarraSuperior titulo="TERMOS DE USO" linkleft='voltar' linkright='' />
        <div className="container-fluid limit-width">
            <div className="mt-5">
                <section dangerouslySetInnerHTML={{ __html: bloco }} />
            </div>
        </div>
    </>
}

export default Termos;