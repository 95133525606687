import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

//funcoes proprias.
import Api from '../Api';
import BarraInferior from '../componentes/BarraInferior';
import BarraSuperior from '../componentes/BarraSuperior';
import DiscoHeader from '../componentes/DiscoHeader';
import IfComponent from '../componentes/IfComponent';

function DiscoFotos() {
    const { id } = useParams();
    const [ disco, setDisco ] = useState({});
    const [ imagens, setImagens ] = useState([]);

    const handleInputChange = (e,idx) => {
        const newValues=[];
        newValues.push(...imagens);
        if (e.target.name==='descricao') {
            newValues[idx].DIF_DESCRICAO=e.target.value;
        }
        if (e.target.name==='ordem') {
            newValues[idx].DIF_ORDEM=e.target.value;
        }
        setImagens(newValues);
        // const { name, value, type, checked } = e.target;
        // const isCheckBox = type === 'checkbox';
        // const data = imagens[name] || {};
        // if (isCheckBox) {
        //     data[value]=checked;
        // }
        // const newValue = isCheckBox ? data : value;
        // setImagens({...imagens, [name]: newValue});
    }

    function VerDisco() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'verfotos');
        bodyFormData.set('disco', id);

        Api.post('discos.php',bodyFormData)
        .then((response) => {
            setDisco(response.data.msg);
            if (response.data.erro==='N') {
                setDisco(response.data.msg);
                setImagens(response.data.msg.FOTOS);
            } else {
                setDisco({DIS_CODIGO:0});
                setImagens([]);
                
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }  

    useEffect(() => {
        VerDisco();
        // eslint-disable-next-line
    },[]);

    function btnRotacionar(codigo, angulo, idx) {
        const formData = new FormData();
        formData.set('op','rotacionarfoto');
        formData.set('codigo',codigo);
        formData.set('angulo',angulo);

        Api.post('discos.php',formData)
        .then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Foto rotacionada com sucesso', //response.data.msg,
                    showConfirmButton: false,
                    timer: 1000
                }); 
                const newValues=[];
                newValues.push(...imagens);
                newValues[idx].DIF_ARQUIVO=response.data.msg;
                setImagens(newValues);
                //setDisco(response.data.msg);
                //setImagens(response.data.msg.FOTOS);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                //setDisco({DIS_CODIGO:0});
                //setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        }); 
    }

    function btnSalvar(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.set('op','atualizarfoto');
        //const data = Object.fromEntries(formData);
        Api.post('discos.php',formData)
        .then((response) => {
            console.log(response.data.msg);
            //setDisco(response.data.msg);
            if (response.data.erro==='N') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.msg,
                    showConfirmButton: false,
                    timer: 1000
                }); 
                //setDisco(response.data.msg);
                //setImagens(response.data.msg.FOTOS);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                //setDisco({DIS_CODIGO:0});
                //setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });        
    }

    function btnExcluir(codigo) {
        console.log('excluir',codigo);
        const formData = new FormData();
        formData.set('op','excluirfoto');
        formData.set('foto',codigo);

        Swal.fire({
            title: 'Confirme!',
            text: "Quer mesmo eliminar a foto "+codigo+"?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, taca pau!',
            cancelButtonText: 'Nãããoooo...'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post('discos.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: response.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                }).catch((response) => {
                    Swal.fire({
                        title: 'Ah nãooooo!',
                        text: response.data.msg,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#212529'
                    });
                })
            }
        })   
    }

    return <>
        <BarraSuperior titulo="FOTOS DO DISCO" linkleft='voltar' linkright=''/>

        <IfComponent condicional={disco.DIS_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o disco.</p>
            </div> 
        </IfComponent>
        <IfComponent condicional={disco.DIS_CODIGO>0}>
            <div className="container limit-width my-5 pt-2">
                <DiscoHeader 
                    imagens={imagens}
                    ART_NOME={disco.ART_NOME}
                    DIF_ARQUIVO={disco.DIF_ARQUIVO}
                    DIS_ANO={disco.DIS_ANO}
                    DIS_CODIGO={disco.DIS_CODIGO}
                    DIS_RARIDADE={disco.DIS_RARIDADE}
                    DIS_TAMANHO={disco.DIS_TAMANHO}
                    DIS_TITULO={disco.DIS_TITULO}
                    DIS_VALOR={disco.DIS_VALOR}
                />
                    
                {imagens.map((imagem,idx) => {
                    return <div key={idx} className="card mb-3 bg-light">
                        <img src={imagem.DIF_ARQUIVO} className="card-img-top" alt={idx}/>
                        <div className="card-body m-0 ps-2 pe-2 pt-2 pb-0 p-inputtext-sm">
                            <form name={'form'+imagem.DIF_CODIGO} onSubmit={btnSalvar} method="POST">
                            <div className="row gx-2">
                                <div className="col-12 mb-2">
                                    <span className="p-float-label">
                                        <InputText className="w-100" maxLength="150" name="descricao" value={imagem.DIF_DESCRICAO || ''} onChange={(e) => handleInputChange(e,idx)} />
                                        <label htmlFor="descricao">DESCRIÇÃO</label>
                                    </span>
                                </div>
                                <div className="col-12 mb-2">
                                    <span className="p-float-label">
                                        <InputNumber className="w-100 p-inputwrapper-filled" name="ordem" value={imagem.DIF_ORDEM || ''} onValueChange={(e) => handleInputChange(e,idx)} mode="decimal" useGrouping={false} locale="pt-BR"/>
                                        <label htmlFor="ordem">ORDEM</label>
                                    </span>
                                    <input type='hidden' name='codigo' value={imagem.DIF_CODIGO} />
                                    <input type='hidden' name='op' value='atualizar' />
                                </div>
                            </div>    
                            <div className="row gx-2 mb-2">      
                                <div className="col-6 py-1">
                                    <button className="btn btn-info w-100" name="rotacionar90" type="button" onClick={() => btnRotacionar(imagem.DIF_CODIGO,90,idx)}><i className="fa fa-redo-alt"></i> 90&ordm;</button>
                                </div>      
                                <div className="col-6 py-1">
                                    <button className="btn btn-info w-100" name="rotacionar180" type="button" onClick={() => btnRotacionar(imagem.DIF_CODIGO,180,idx)}><i className="fa fa-redo-alt"></i> 180&ordm;</button>
                                </div>      
                                <div className="col-6 py-1">
                                    <button className="input-group-text btn btn-success w-100" name="salvar" type="submit"><i className="fa fa-check"></i> Salvar</button>
                                </div>      
                                <div className="col-6 py-1">
                                    <button className="btn btn-danger w-100" name="excluir" type="button" onClick={() => btnExcluir(imagem.DIF_CODIGO)}><i className="fa fa-trash"></i> Excluir</button>
                                </div>   
                            </div>    
                            </form>
                        </div>
                    </div>                    
                }) }
            </div>
        </IfComponent>
        <BarraInferior active=""/>
    </>
}

export default DiscoFotos;