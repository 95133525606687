import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { AuthContext } from '../contextos/Auth';
import AcervoCard from '../componentes/AcervoCard';
import BarraInferior from '../componentes/BarraInferior';
import BarraSuperior from '../componentes/BarraSuperior';
import ProfileHeader from '../componentes/ProfileHeader';
import Totalizadores from '../componentes/Totalizadores';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function ProfileId() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const { id } = useParams();
    const [ resumo, setResumo ] = useState({});
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ listagem, setListagem ] = useState([]);
    const [ scrollRatio, setScrollRatio ] = useState(null);

    async function getProfile() {
        const formData = new FormData();
        formData.set('op','resumo');
        formData.set('id',id);
        const response = await Api.post('profile.php',formData);
        if (response.data.erro==='S') {
            logout();
            //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            setResumo({USU_CODIGO: 0})
        } else {
            setResumo(response.data.msg[0]);
        }
    }

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line
    },[]);

    function listarVitrine(nPagina,bFinal,bLimpar) {        
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
                formData.set('op', 'acervousuario');
                formData.set('pagina', novaPagina);
                formData.set('id', id);
            
            Api.post('vitrine.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch((response) => {
                setCarregando(false);
                console.log('CATCH: '+JSON.stringify(response.data));
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {
        if (scrollRatio > 0) {
            listarVitrine(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]); 

    return <>
        <BarraSuperior titulo='PERFIL DO COLECIONADOR' linkleft='voltar' linkright='' />
            <IfComponent condicional={resumo.USU_CODIGO > 0}>
                <div className='container-fluid limit-width mt-5'>
                    <ProfileHeader nome={resumo.USU_NOME} id={resumo.USU_CODIGO} avatar={resumo.USU_FOTOPERFIL}/>
                    <Totalizadores qtdeArtistas={resumo.QTDEARTISTAS} qtdeGeneros={resumo.QTDEGENEROS} qtdeDiscos={resumo.QTDEDISCOS} valorDiscos={resumo.VALORDISCOS} />
                </div>
                <div className="container-fluid limit-width">
                    <div className="rounded py-1 px-2" style={{ backgroundColor: '#eaeaea', border: '1px solid #dbdbdb'}}>
                        <section dangerouslySetInnerHTML={{ __html: resumo.USU_APRESENTACAO }} />
                    </div>
                </div>
            </IfComponent>
            <IfComponent condicional={resumo.USU_CODIGO <= 0}>
                <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-user"></i></span><br/>Perfil não localizado.</p>
                </div> 
            </IfComponent>
            <IfComponent condicional={ listagem.length > 0}>
            <div className="container limit-width mt-2 mb-5">
                <div className="rounded py-1 px-2 mb-2" style={{ backgroundColor: '#eaeaea', border: '1px solid #dbdbdb'}}>
                    <p className='lh-0 p-0 mb-0'><strong>MEU ACERVO</strong></p>
                </div>

            {                     
                listagem.map(vitrine => (
                    <AcervoCard 
                        key={vitrine.DIS_CODIGO}
                        acervo={false}
                        ART_NOME={vitrine.ART_NOME}
                        DES_DISCO={vitrine.DES_DISCO}
                        DIF_ARQUIVO={vitrine.DIF_ARQUIVO}
                        DIS_ANO={vitrine.DIS_ANO}
                        DIS_CODIGO={vitrine.DIS_CODIGO}
                        DIS_TITULO={vitrine.DIS_TITULO}
                        DIS_RARIDADE={vitrine.DIS_RARIDADE}
                        DIS_VALOR={vitrine.DIS_VALOR}
                        GEN_DESCRICAO={vitrine.GEN_DESCRICAO}
                        PAI_NOMEBR={vitrine.PAI_NOMEBR}
                        USU_NOME={vitrine.USU_NOME}
                        PATH = 'vitrine' 
                    />                   
                ))
            }   
        </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>

        <BarraInferior />
    </>
}

export default ProfileId;