import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import BarraSuperior from '../componentes/BarraSuperior';

//funcoes proprias.
import { AuthContext } from '../contextos/Auth';

function ResetSenha() {
    const { resetar } = useContext(AuthContext);
    const [ email, setEmail ] = useState('');
    const [ solicitando, setSolicitando ] = useState(false);

    async function resetarSenha() {
        setSolicitando(true);
        await resetar(email);
        setSolicitando(false);
    }

    return <>
        <BarraSuperior titulo='RESETAR SENHA' linkleft='voltar' linkright='' />
        <div className="container-fluid limit-width py-4">
            <div className="mt-5">
                <p>Lembre-se: O seu email é o canal de comunicação entre nós e você. Mantenha seus dados atualizados.<br/><br/>
                    Se seu email estiver incorreto, faça a <a href="/alteraremail.php" className="text-success fw-bold">solicitação de alteração de email.</a><br/><br/>
                    Caso ainda tenha dificuldade em acessar o site, entre em <Link to="/contato" className="text-success fw-bold">contato</Link> conosco informando seu nome e email.<br/><br/>
                    Para solicitar uma nova senha, preencha os campos abaixo:</p>
                <div className="row mt-3 mb-5 gx-2">
                    <div className="col-12 form-floating mb-2"><input onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" id="email" name="email" placeholder="Email" required autoFocus /><label className="control-label" htmlFor="email">Email</label></div>
                </div>
                {   solicitando
                        ? <button type="button" onClick={resetarSenha} className="btn btn-lg btn-success w-100" disabled><i className='fa fa-fw fa-spin fa-spinner'></i> Solicitando...</button>
                        : <button type="button" onClick={resetarSenha} className="btn btn-lg btn-success w-100">Solicitar Nova Senha</button>
                }
            </div>
        </div>
    </>
}

export default ResetSenha;