import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { Password } from 'primereact/password';

// funcoes proprias.
import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';

function AlterarSenha () {
    let navigate = useNavigate();
    const [ formValues, setFormValues ] = useState({});

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const isCheckBox = type === 'checkbox';
        const data = formValues[name] || {};
        if (isCheckBox) {
            data[value]=checked;
        }
        const newValue = isCheckBox ? data : value;
        setFormValues({...formValues, [name]: newValue});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        //const data = Object.fromEntries(formData);
        Api.post('usuarios.php',formData).then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.msg,
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/profile');
                    }
                }); 
                //const emptyValues={};
                //setFormValues(emptyValues);
            } else {
                Swal.fire({
                    title: 'Ooops...',
                    text: response.data.msg,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#212529'
                });
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
        });
    }

    return <>
        <BarraSuperior titulo='ALTERAR SENHA' linkleft='voltar' linkright='' />
        <div className="container-fluid limit-width mt-5 py-3">
            <p>Utilize sempre senhas fortes com letras maiúsculas e minúsculas e números.</p>
            <div className="row mt-3 mb-3 gx-2 p-inputtext-sm">
                <form onSubmit={handleSubmit}>
                    <div className="col-12 mb-2">
                        <span class="p-float-label">
                            <Password className="w-100" name='senhaatual' value={formValues.senhaAtual} onChange={handleInputChange} toggleMask autoComplete='false' required/>
                            <label htmlFor="nome">SENHA ATUAL</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span class="p-float-label">
                            <Password className="w-100" name='novasenha' value={formValues.novaSenha} onChange={handleInputChange} toggleMask autoComplete='false' required/>
                            <label htmlFor="nome">NOVA SENHA</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span class="p-float-label">
                            <Password className="w-100" name='confirmacaosenha' value={formValues.confirmaSenha} onChange={handleInputChange} toggleMask autoComplete='false' required/>
                            <label htmlFor="assunto">CONFIRME A NOVA SENHA</label>
                        </span>
                    </div>
                    <input type="hidden" name="op" value="alterarsenha" />
                    <Button type="submit" className="btn btn-lg btn-success w-100 mt-5">ALTERAR</Button>
                </form>
            </div>           
        </div>
    </>
}

export default AlterarSenha;